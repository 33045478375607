import axios from 'axios'

export const assignOrderToMe = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQueue.php', post, { params: { action: 'assignOrderToMe' } })
    .then(({ data: { log } }) => resolve(log))
    .catch(e => reject(e))
})

export const unassignOrders = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQueue.php', { }, { params: { action: 'unassignOrders' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const finishOrder = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQueue.php', post, { params: { action: 'finishOrder' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const getNext = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQueue.php', post, { params: { action: 'getNext' } })
    .then(({ data: { log } }) => resolve(log))
    .catch(e => reject(e))
})
