import * as actions from './actions'

const state = {
  list: {},
  sort: {},
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    list: state => state.list,
    sort: state => state.sort,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setSort(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'field')) {
        state.sort.field = payload.field
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'direction')) {
        state.sort.direction = payload.direction
      }
    },
  },
}
