import axios from 'axios'
import _ from 'lodash'
import { TYPE } from 'vue-toastification'
import { useStore } from '@/store'
import { getMachineId } from './func.js'

const getHeader = () => {
  const header = {}
  const cookie = {}
  if (useStore().getters.returnDebug) {
    cookie.allowShowDebug = useStore().getters.returnDebug
    header['X-debug'] = useStore().getters.returnDebug
  }
  if (Object.keys(cookie).length > 0) {
    header['X-cookie'] = JSON.stringify(cookie)
  }

  if (useStore().getters.authToken) {
    header['X-Token'] = $.cookie && $.cookie('usession')
  }
  header['X-Machine-Id'] = getMachineId()
  return header
}

$.ajaxSetup({
  beforeSend(jqXHR, settings) {
    _.map(getHeader(), (value, key) => {
      jqXHR.setRequestHeader(key, value)
    })
  },
})

const toastMessage = async (d, r) => {
  if (d instanceof Blob && d.type === 'application/json') {
    d = JSON.parse(await d.text())
  }
  if (typeof r === 'undefined') return
  const toastId = 'toastId' in r.config ? r.config.toastId : r.config.url
  let type = 'default'
  if (typeof d !== 'object') return
  if ('error' in d) {
    type = TYPE.ERROR
  } else if ('info' in d) {
    type = TYPE.INFO
  } else if ('warning' in d) {
    type = TYPE.INFO
  } else if ('success' in d || 'notification' in d) {
    type = TYPE.SUCCESS
  }
  const content = d.message || d.error || d.text || d.notification || d.warning || d.info || d.success
  const toastComponent = {
    template: '<span v-html="content"></span>',
    data: () => ({ content }),
  }

  if (content && typeof content !== 'object' && content !== true && String(content).length > 0 && typeof content !== 'function') {
    const opts = [
      toastId,
      {
        content: toastComponent,
        options: { timeout: d.timeout || 3000, type, ...d.options || {} },
      },
      true,
    ]
    window.appJs?.$toast?.update(...opts)
  } else {
    window.appJs?.$toast?.dismiss(toastId)
  }
}

axios.interceptors.request.use(
  config => {
    if (!config?.headers?.['X-Auth-Token']) {
      _.map(getHeader(), (value, key) => {
        if (config?.headers) config.headers[key] = value
      })
    }

    if (config.toast) {
      toastMessage({
        info: config.toast.text || config.toast,
        options: {
          timeout: false,
          closeButton: false,
          ...config.toast.options || {},
        },
      }, { config })
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    if ('spinner' in window) window.spinner('hide')
    if (typeof response.data === 'object' && response.data) {
      toastMessage(response.data, response)
    }
    return Promise.resolve(response)
  },
  error => {
    if (error?.response?.status === 401) {
      document.location = '/login'
    }

    if ('spinner' in window) window.spinner('hide')
    if ('response' in error && error.response) {
      toastMessage(error.response.data, error.response)
      return Promise.reject(error.response)
    }
    if (('message' in error && error.message !== 'Resend request') || !('message' in error)) {
      toastMessage({ error: 'Service Unavailable or Aborted request' }, error.response)
    }
    return Promise.reject(error)
  },
)
