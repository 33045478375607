import axios from 'axios'
import { apiV1 } from '@/config'

export const loadUserApiTokens = ({ commit }, { userId }) => new Promise((resolve, reject) => {
  axios.get(`${apiV1}/user/api-key/list`, { params: { userId } })
    .then(({ data: { list } }) => resolve(list))
    .catch(error => reject(error))
})

export const createUserApiToken = ({ commit }, { userId }) => new Promise((resolve, reject) => {
  axios.post(`${apiV1}/user/api-key/create`, {}, { params: { userId } })
    .then(({ data }) => {
      commit('CREATED_USER_API_TOKEN', { token: data, userId })
      resolve()
    })
    .catch(error => {
      reject(error)
    })
})

export const deleteUserApiToken = ({ commit }, { uid, userId }) => new Promise((resolve, reject) => {
  axios.delete(`${apiV1}/user/api-key/${uid}/delete`, { params: { userId } })
    .then(data => {
      commit('DELETED_USER_API_TOKEN', { uid, userId })
    })
})
