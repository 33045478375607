import axios from 'axios'

export const loadApplyDriver = ({ commit }, uid) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDrivers.php', { uid }, { params: { action: 'getApplyDriver' }, toast: 'Loading Dirver info, wait..' })
    .then(({ data }) => {
      commit('SET_APPLY_DRIVER', data.user)
      resolve(data.user)
    })
    .catch(error => {
      commit('CLEAR_APPLY_DRIVER')
      reject(error)
    })
})

export const loadDriver = ({ commit }, uid, waitValue = false) => new Promise((resolve, reject) => {
  if (!/\d+/.test(uid)) {
    commit('ADD_DRIVER', { uid, wait: 'undefined' })
    resolve(null)
    return
  }
  commit('ADD_DRIVER', { uid, wait: waitValue })

  axios.post('/ajax/PullDrivers.php', { uid }, { params: { action: 'getDriver' } })
    .then(({ data }) => {
      commit('ADD_DRIVER', { uid: data.user.uid, user: data.user })
      resolve(data.user)
    })
    .catch(error => {
      reject(error)
    })
})

export const resetApplyDriver = ({ commit }) => new Promise((resolve, reject) => {
  commit('CLEAR_APPLY_DRIVER')
  resolve()
})

export const saveApplyDriver = ({ commit }, driver) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDrivers.php', { driver }, { params: { action: 'saveApplyDriver' }, toast: 'Saving driver information, wait..' })
    .then(({ data }) => {
      // commit('SET_APPLY_DRIVER', data.user);
      resolve(data.user)
    })
    .catch(error => {
      reject(error)
    })
  resolve()
})

export const cancelDeletion = ({ commit }, { uid }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDrivers.php', { driverId: uid }, { params: { action: 'cancelDeletion' }, toast: 'Saving driver information, wait..' })
    .then(({ data }) => {
      commit('SET_APPLY_DRIVER', data.user)
      resolve(data.user)
    })
    .catch(error => {
      reject(error)
    })
  resolve()
})
