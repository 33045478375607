import axios from 'axios'

export const searchInfo = id => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { id }, { params: { action: 'searchInfo' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getHubName = id => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { id }, { params: { action: 'getHubName' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const seeActivity = id => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { id }, { params: { action: 'seeActivity' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const validateQueueGetData = status => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { status }, { params: { action: 'validateQueueGetData' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const validateQueue = (barcode, status) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { barcode, status }, { params: { action: 'validateQueue' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const changeStatus = (id, request) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { id, request }, { params: { action: 'changeStatus' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const scanByFilter = (id, filter) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { id, filter }, { params: { action: 'scanByFilter' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getOrderTypes = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', {}, { params: { action: 'getOrderTypes' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getOrderActivities = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', {}, { params: { action: 'getOrderActivities' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getHubs = () => new Promise((resolve, reject) => {
  // resolve({
  //   hubs: {
  //     1: {
  //       title: 'Brooklyn',
  //       color: '#317052',
  //     },
  //     22: {
  //       title: 'Long Island',
  //       color: '#cacc19',
  //     },
  //     25: {
  //       title: 'LOS ANGELES',
  //       color: '#5dfc00',
  //     },
  //     29: {
  //       title: 'Connecticut',
  //       color: '#c378f0',
  //     },
  //     48: {
  //       title: 'Boston',
  //       color: '#317052',
  //     },
  //     62: {
  //       title: 'Pennsylvania',
  //       color: '#593554',
  //     },
  //     67: {
  //       title: 'New Jersey',
  //       color: '#0a09a4',
  //     },
  //     72: {
  //       title: 'Miami',
  //       color: '#c36b04',
  //     },
  //     78: {
  //       title: 'Orange County',
  //       color: '#1ac8cf',
  //     },
  //   },
  //   runtime: 0.03839898109436035,
  // })

  axios.post('/ajax/PullScan.php', {}, { params: { action: 'getHubs' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const readyForDeliveryScan = barcode => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { barcode }, { params: { action: 'readyForDeliveryScan' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getRoutes = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', {}, { params: { action: 'getRoutes' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const driverOutGetData = routeId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { routeId }, { params: { action: 'driverOutGetData' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const assignDriver = barcode => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { barcode }, { params: { action: 'assignDriver' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const driverOutScan = (barcode, routeId, driverId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { barcode, routeId, driverId }, { params: { action: 'driverOutScan' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const driverOutComplete = routeId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { routeId }, { params: { action: 'driverOutComplete' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const returnedScan = barcode => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { barcode }, { params: { action: 'returnedScan' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const copayLastTwoDays = driverId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { driverId }, { params: { action: 'copayLastTwoDays' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCopayData = driverId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { driverId }, { params: { action: 'getCopayData' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getClientAndStopData = (orderId, clientId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { orderId, clientId }, { params: { action: 'getClientAndStopData' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const errorDescription = (orderId, message) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { orderId, message }, { params: { action: 'errorDescription' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const copayUnpaid = copayId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { copayId }, { params: { action: 'copayUnpaid' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const copayDriver = copayId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullScan.php', { copayId }, { params: { action: 'copayDriver' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
