import * as actions from './actions.js'

import { setItem, unsetItem } from '@/helper.js'

const state = {
  equipmentTypes: {},
}

export default {
  actions,
  state,
  getters: {
    equipmentTypes: state => state.equipmentTypes,
  },
  mutations: {
    CLEAR_TYPES(state) {
      unsetItem(state, 'equipmentTypes')
    },
    SET_TYPES(state, types) {
      setItem(state, 'equipmentTypes', types)
    },
  },
}
