import { reactive, watch } from 'vue'
import PouchDB from 'pouchdb'

import _ from 'lodash'
import { setItem as setItemVue } from '@/helper.js'

const list = {}

const storedRow = (storedSettings, type) => {
  const storage = type === 'local' ? localStorage : window.sessionStorage
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    let item
    try {
      list[key] = type
      item = JSON.parse(storage.getItem(`app.storage.${key}`))
    } catch (e) { }
    settings[key] = item === null ? storedSettings[key] : item
  })
  return settings
}
export const local = storedSettings => storedRow(storedSettings, 'local')
export const session = storedSettings => storedRow(storedSettings, 'session')
export const setItem = (store, key, value) => {
  store[key] = value
  if (key in list) {
    const storage = list[key] === 'local' ? localStorage : window.sessionStorage
    storage.setItem(`app.storage.${key}`, JSON.stringify(value))
  }
}

export const removeItem = (store, key) => {
  store[key] = null
  if (key in list) {
    const storage = list[key] === 'local' ? localStorage : window.sessionStorage
    storage.removeItem(`app.storage.${key}`)
  }
}

const databases = {}
export const pouchdb = storedSettings => {
  const datasets = reactive({})

  Object.keys(storedSettings).forEach(key => {
    if (!(key in databases)) {
      databases[key] = new PouchDB(key)
    }

    setItemVue(datasets, key, {})
    databases[key].allDocs({ include_docs: true })
      .then(data => {
        _.map(data.rows, ({ id, doc }) => {
          setItemVue(datasets[key], id, doc.value)
        })
      })

    // databases[key].changes({
    //   live: true,
    //   include_docs: true,
    // }).on('change', ({ id, doc }) => {
    //   setItemVue(datasets[key], id, doc.value)
    // }).on('error', err => {
    //   console.log(err)
    // })

    // watch(() => datasets[key], e => {
    //   console.log('watch', e)
    // }, { deep: true })
  })
  setInterval(() => {
    // if ('219207777' in datasets.publicUsers) {
    //   datasets.publicUsers['219207777'].name = new Date().toJSON()
    // }
  }, 2000)
  return datasets
}
export const pouchSet = ([store, db], key, value) => {
  value = {
    ...value,
    saveDateTime: new Date().getTime(),
  }

  setItemVue(store[db], key, value)
  if (value !== Infinity && !value.reload) {
    databases[db].get(String(key))
      .then(doc => databases[db].remove(doc._id, doc._rev))
      .catch(() => {})
      .finally(() => {
        databases[db].put({
          _id: String(key),
          value,
        })
      })
  }
}
