import axios from 'axios'

export const getRegions = ({ commit }, { clientId, typeId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { clientId, typeId }, { params: { action: 'getRegions' } })
    .then(({ data: { regions } }) => resolve(regions))
    .catch(e => reject(e))
})

export const saveRegion = ({ commit }, { userId, regionId, name, group, facility, data, presets, rate, typeId, fields }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { userId, regionId, name, group, facility, data, presets, rate, typeId, fields }, { params: { action: 'saveRegion' } })
    .then(({ data: { region } }) => resolve(region))
    .catch(e => reject(e))
})

export const removeRegion = ({ commit }, { regionId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { regionId }, { params: { action: 'removeRegion' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const removeAllRegions = ({ commit }, { clientId, typeId, regionIds }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { clientId, typeId, regionIds }, { params: { action: 'removeAllRegions' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const addRegionsToUser = ({ commit }, { regionIds, clientId, typeId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { regionIds, clientId, typeId }, { params: { action: 'addRegionsToUser' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const copyRegionSettings = ({ commit }, { fromClientId, toClientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { fromClientId, toClientId }, { params: { action: 'copyRegionSettings' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const getMyRegions = ({ commit }, { clientId, typeId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { clientId, typeId }, { params: { action: 'getMyRegions' } })
    .then(({ data: { regions } }) => resolve(regions))
    .catch(e => reject(e))
})

export const getUsesRegion = ({ commit }, { regionId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClientsRegionManage.php', { regionId }, { params: { action: 'getUsesRegion' } })
    .then(({ data: { users } }) => resolve(users))
    .catch(e => reject(e))
})
