import axios from 'axios'

export const transferCall = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCalls.php', post, { params: { action: 'transferCall' } })
    .then(({ data: { call } }) => resolve(call))
    .catch(e => reject(e))
})

export const conferenceCall = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCalls.php', post, { params: { action: 'conferenceCall' } })
    .then(() => resolve())
    .catch(e => reject(e))
})
