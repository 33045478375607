import * as actions from './actions.js'

import { setItem, unsetItem } from '@/helper.js'

const state = {
  departments: {},
  types: {},
  errors: {
    name: '',
    type: '',
  },
}

export default {
  actions,
  state,
  getters: {
    departments: state => state.departments,
    errors: state => state.errors,
    types: state => state.types,
  },
  mutations: {
    SET_TYPES(state, types) {
      setItem(state, 'types', types)
    },
    SET_DEPARTMENTS(state, departments) {
      setItem(state, 'departments', departments)
    },
    CLEAR_DEPARTMENTS(state) {
      unsetItem(state, 'departments')
    },
    SET_ERROR(state, payload) {
      state.errors[payload.key] = payload.error
    },
    CLEAR_ERROR(state, key) {
      state.errors[key] = ''
    },
    CLEAR_ERRORS(state) {
      Object.keys(state.errors).forEach(key => {
        state.errors[key] = ''
      })
    },
  },
}
