import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'

const state = {
  routeNames: null,
  routeNameGroups: null,
  allowIntersect: 'no',
}

export default {
  state,
  actions,
  getters: {
    routeNames: state => {
      if (state.routeNames === null) {
        setItem(state, 'routeNames', { reload: true })
      }

      if (state.routeNames?.reload) {
        setItem(state.routeNames, 'reload', false)

        actions.getRouteNames(useStore(), { })
          .then(routeNames => { routeNames.reload = false; setItem(state, 'routeNames', routeNames) })
          .catch(() => { setItem(state, 'routeNames', Infinity) })
      }
      return state.routeNames
    },
    routeNameGroups: state => {
      if (state.routeNameGroups === null) {
        setItem(state, 'routeNameGroups', { reload: true })
      }

      if (state.routeNameGroups?.reload) {
        setItem(state.routeNameGroups, 'reload', false)

        actions.getRouteNameGroups(useStore(), { })
          .then(routeNameGroups => { routeNameGroups.reload = false; setItem(state, 'routeNameGroups', routeNameGroups) })
          .catch(() => { setItem(state, 'routeNameGroups', Infinity) })
      }
      return state.routeNameGroups
    },
    allowIntersect: state => state.allowIntersect,
  },
  mutations: {
    setAllowIntersect(state, value) {
      state.allowIntersect = value
    },
  },
  namespaced: true,
}
