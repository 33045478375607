import _ from 'lodash'
import New from './New/index.js'
import List from './List/index.js'
import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'
import * as Store from '@/utils/store.js'

const state = {
  checkDelivery: {},
  ordersDetails: {},
  csvImportProfiles: null,
  ...Store.session({
    selectedCsvImportProfile: null,
    notesResult: null,
    orderStatuses: null,
  }),
}

export default {
  state,
  actions,
  getters: {
    selectedCsvImportProfile: state => state.selectedCsvImportProfile,
    orderDetails: state => orderId => {
      if (!orderId) return {}
      if (typeof state.ordersDetails[orderId] === 'undefined') {
        setItem(state.ordersDetails, orderId, { mute: false, reload: true, loading: true })
      }

      if (state.ordersDetails[orderId]?.reload) {
        state.ordersDetails[orderId].reload = false
        state.ordersDetails[orderId].loading = true
        actions.getDetails(useStore(), { orderId, force: useStore().getters.currentUser?.permission?.isAdmin })
          .then(order => {
            order.mute = false
            order.reload = false
            order.loading = false
            setItem(state.ordersDetails, orderId, order)
          })
          .catch(() => { setItem(state.ordersDetails, orderId, Infinity) })
      }

      return state.ordersDetails[orderId]
    },
    csvImportProfiles: stage => {
      if (!stage.csvImportProfiles) {
        actions.loadCsvImportProfiles(useStore())
          .then(list => { stage.csvImportProfiles = list })
      }
      return stage.csvImportProfiles
    },
    notesResult: stage => {
      if (!stage.notesResult) {
        actions.getNotesResultPrepared(useStore())
          .then(list => { stage.notesResult = list })
      }
      return stage.notesResult ?? []
    },
    getOrderStatuses: stage => {
      if (!stage.orderStatuses) {
        actions.getOrderStatuses(useStore())
          .then(statuses => { Store.setItem(state, 'orderStatuses', statuses) })
      }
      return _.filter(stage.orderStatuses ?? [], e => e.isStatus)
    },
    getOrderSubStatuses: stage => {
      if (!stage.orderStatuses) {
        actions.getOrderStatuses(useStore())
          .then(statuses => { Store.setItem(state, 'orderStatuses', statuses) })
      }
      return _.filter(stage.orderStatuses ?? [], e => e.isSubStatus)
    },
  },
  mutations: {
    selectedCsvImportProfile(state, value) {
      Store.setItem(state, 'selectedCsvImportProfile', value)
    },
  },
  modules: {
    New,
    List,
  },
  namespaced: true,
}
