import * as actions from './actions.js'

const state = {
  ordersCount: null,
  totalCreated: 0,
  totalDelivered: 0,
  filter: {
    created_at_d: {
      from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    ordersCount: state => state.ordersCount,
    totalCreated: state => state.totalCreated,
    totalDelivered: state => state.totalDelivered,
    filter: state => state.filter,
  },
  mutations: {
    SET_ORDERS_COUNT(state, payload) {
      state.ordersCount = payload
    },
    SET_TOTAL_CREATED(state, payload) {
      state.totalCreated = payload
    },
    SET_TOTAL_DELIVERED(state, payload) {
      state.totalDelivered = payload
    },
    SET_FILTER(state, payload) {
      state.filter = payload
    },
  },
}
