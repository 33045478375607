/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { apiV1 } from '@/config'

export const getList = async context => {
  const params = {
    ...context.rootGetters['FinancesRx4Route/filter'],
  }
  const { data: request } = await axios.get(`${apiV1}/finances/per-day`, { params })

  context.commit('setList', request.data)
}
