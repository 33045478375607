import * as actions from './actions'

const state = {
  loading: false,
  clientId: null,
  data: [],
}

export default {
  state,
  actions,
  getters: {
    menus: state => {
      const menus = []
      let currentGroup = null
      if (state.data === Infinity) {
        return menus
      }
      state.data.forEach(menu => {
        if (currentGroup === null) {
          currentGroup = menu.group
        } else if (currentGroup !== menu.group) {
          currentGroup = menu.group
          menus.push({ line: '-' })
        }

        menu.to = menu.is_common
          ? {
            name: 'account-my-settings',
            params: { group: menu.code },
          }
          : {
            name: `account-my-${menu.code}`,
          }

        menus.push({
          code: menu.code,
          icon: menu.icon,
          to: menu.to,
        })
      })

      return menus
    },
    data: state => state.data,
    loading: state => state.loading,
    clientId: state => state.clientId,
  },
  mutations: {
    loadData(store, clientId) {
      store.loading = true
      store.clientId = clientId
      this.dispatch('Account/getDataForClient')
    },
    setClientData(store, data) {
      store.loading = false
      store.data = data
    },
  },
  namespaced: true,
}
