import moment from 'moment-timezone'

/**
 * @param {Number|String} date
 * @param {String|Null} tz
 * @returns {moment.Moment|Null}
 */
export const makeDate = (date, tz = 'America/New_York') => {
  if (date === null) {
    return null
  }

  const time = Number.isInteger(date) ? moment.unix(date).tz(tz) : moment.tz(date, tz)
  if (!time.isDST()) {
    time.add(-1, 'hour')
  }

  return time
}

/**
 * @param {String|moment.Moment|Null} date
 * @param {String|moment.Moment|Null} then
 * @returns {Number}
 */
export const diffSeconds = (date, then) => moment(date).diff(moment(then), 'seconds')

/**
 * @param {String|moment.Moment|Null} date
 * @param {String} [_default = '—']
 * @returns {String}
 */
export const humanizeMonth = (date, _default = '—') => (date ? moment(date).format('MMMM YYYY') : _default)

/**
 * @param {Number} timestamp
 * @param {String} [_default = '—']
 * @returns {String}
 */
export const humanizeTimeDiff = (timestamp, _default = '—') => {
  if (timestamp === null) {
    return _default
  }

  timestamp = Math.ceil(timestamp)

  const format = []
  let temp = timestamp

  const d = parseInt(timestamp / 86400, 10)
  if (d) format.push(`${d}d`)
  temp %= 86400

  const h = parseInt(temp / 3600, 10)
  if (h) format.push(`${h}h`)
  temp %= 3600

  const m = parseInt(temp / 60, 10)
  if (m) format.push(`${m}m`)
  temp %= 60

  if (temp || !format.length) format.push(`${temp}s`)

  return format.join(' ')
}

/**
 * @param {Number|moment.Moment} date
 * @returns {String}
 */
export const timeFormat = date => makeDate(date).format('hh:mm a')
