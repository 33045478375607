import axios from 'axios'

export const loadEquipmentTypes = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', {}, { params: { action: 'getTypes' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      commit('CLEAR_TYPES')
      reject(error)
    })
})

export const addEquipmentType = ({ commit }, { title }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { title }, { params: { action: 'addEquipmentType' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      reject(error)
    })
})

export const removeEquipmentType = ({ commit }, { slug }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { slug }, { params: { action: 'removeEquipmentType' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      reject(error)
    })
})

export const calcCountsEquipment = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipmentsTableRedesign.php', { countBy: ['type'] }, { params: { action: 'calcCounts' } })
    .then(({ data: { counters } }) => {
      resolve(counters.type)
    })
    .catch(error => {
      reject(error)
    })
})

export const createEquipment = ({ commit }, { slug, num }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { slug, num }, { params: { action: 'createEquipment' } })
    .then(({ data }) => {
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})

export const confirmPrintedEquipment = ({ commit }, { code }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { code }, { params: { action: 'confirmPrintedEquipment' } })
    .then(({ data }) => {
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})
