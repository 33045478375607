/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { apiV1 } from '@/config'
// import { useStore } from '@/store'

export const getList = async context => {
  // const store = useStore()
  const params = {
    ...context.rootGetters['FinancesRx4Route/filter'],
    ...context.state.filter,
    // sort: {
    //   ...context.state.sort,
    // },
  }
  const { data: request } = await axios.get(`${apiV1}/finances/all`, { params })

  context.commit('setList', request.data)
  // context.commit('setSummary', request.summary)
  // context.commit('setCounts', request.counts)
  // store.commit('FinancesRx4Route/setCounts', request.counts)
}
