import axios from 'axios'

export const loadReport = ({ commit }) => new Promise((resolve, reject) => {
  commit('START_LOAD')
  axios.post('/reports/finance/get-common-revenue-report', {})
    .then(({ data }) => {
      commit('SET_REPORTS', data)
    })
    .catch(error => { reject(error) })
})

export const addUser = ({ commit }, userId) => new Promise(() => {
  axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'getPublicUserInfo' } })
    .then(({ data: { info } }) => {
      commit('SET_USER_ADD', info)
    })
})

export const deleteUser = ({ commit }, userUId) => new Promise(() => {
  commit('SET_USER_FOR_DELETE', userUId)
})

export const saveUsers = ({ commit, state }) => new Promise(() => {
  axios.post(
    '/reports/finance/update-pharmacies-to-common-revenue-calculation',
    {
      add: state.usersForAdd,
      delete: state.usersForDelete,
    },
  ).then(
    () => loadReport({ commit })
      .then(() => commit('CLEAR_USERS_CHANGES')),
  )
})

export const addCustomField = ({ commit }, data) => new Promise(() => {
  commit('ADD_CUSTOM_FIELD', data)
})

export const deleteCustomField = ({ commit }, uid) => new Promise(() => {
  commit('DELETE_CUSTOM_FIELD', uid)
})

export const deleteTempCustomField = ({ commit }, index) => new Promise(() => {
  commit('DELETE_TEMP_CUSTOM_FIELD', index)
})

export const saveCustomFields = ({ commit, state }) => new Promise(() => {
  axios.post(
    '/reports/finance/update-custom-fields-common-revenue-calculation',
    {
      add: state.customFieldsForAdd,
      delete: state.customFieldsForDelete,
    },
  ).then(
    () => loadReport({ commit })
      .then(() => commit('CLEAR_TEMP_CUSTOM_FIELDS')),
  )
})

export const setCustomFieldValue = ({ commit }, data) => new Promise(() => {
  axios.post(
    '/reports/finance/set-custom-field-common-revenue-calculation',
    data,
  ).then(
    () => loadReport({ commit }),
  )
})
