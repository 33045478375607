import axios from 'axios'

export const load = ({ state, commit }, clientId) => new Promise((resolve, reject) => {
  if (state.clientId !== clientId && !state.load) {
    commit('loadData', clientId)
  }
})

export const getDataForClient = ({ state, commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullAccount.php', { client_id: state.clientId }, { params: { action: 'getData' } })
    .then(({ data: { data } }) => {
      commit('setClientData', data)
    })
    .catch(() => {
      commit('setClientData', Infinity)
    })
})

export const save = ({ state, commit }, data) => new Promise((resolve, reject) => {
  const formData = new FormData()
  formData.append('client_id', state.clientId)
  Object.keys(data).forEach(key => {
    if ([null, undefined].includes(data[key])) {
      return
    }

    if (Array.isArray(data[key]) && data[key].length === 0) {
      formData.append(key, data[key])
    }
    if (data[key] instanceof File || typeof data[key] !== 'object') {
      formData.append(key, data[key])
    } else {
      Object.keys(data[key]).forEach(objectKey => {
        if (data[key][objectKey] && typeof data[key][objectKey] === 'object') {
          Object.keys(data[key][objectKey]).forEach(childObjectKey => {
            formData.append(`${key}[${objectKey}][${childObjectKey}]`, data[key][objectKey][childObjectKey])
          })
        } else if (data[key][objectKey]) {
          formData.append(`${key}[${objectKey}]`, data[key][objectKey])
        }
      })
    }
  })
  console.log(formData)

  axios.post(
    '/ajax/PullAccount.php?action=saveData',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(({ data: { success, errors, bankAccount } }) => {
      if (success) {
        resolve({ bankAccount })
      } else {
        reject(errors.join(','))
      }
    })
})
