(() => {
  L.TextIcon = L.Icon.extend({
    options: L.extend({
      className: 'leaflet-div-icon',
    }, new L.Icon.Default().options),

    initialize(options) {
      let marker
      let iconUrl

      if (!marker && 'stopInfo' in options) {
        let stop_num = options.stopInfo.stop
        let color = '#acb7bf'

        if (options.stopInfo.name === 'F' || options.stopInfo.name === 'S') {
          color = '#01a8fe'
          stop_num = options.stopInfo.name
        }

        if (options.stopInfo.status === 'Delivered') {
          color = '#46be8a'
        } else if (options.stopInfo.status === 'Delivery Attempted') {
          color = '#ffa7a2'
        }

        const x = 45
        let markX = 7 + x
        if (stop_num > 9) {
          markX = 2.5 + x
        }

        let delivery_time = ''
        let textColor = 'black'
        let stroke_color = ''

        if (options.stopInfo.map_name === 'routes') {
          const blackColor = ['SAME DAY', 'Exchange', 'Time Window Next Day']
          color = `#${options.stopInfo.bgcolor}`
          stroke_color = `#${options.stopInfo.stroke_color}`

          textColor = blackColor.includes(options.stopInfo.status_or_type) ? 'black' : 'white'

          delivery_time = `
          <g style="transform: translate(0, 40px)">
            <rect x="0" y="0" width="111" stroke="${stroke_color}" height="15" fill="${color}" rx="5" ry="5"/>
            <text font-weight="bold" x="4" y="12" font-family="Helvetica, Arial, sans-serif" font-size="14" fill="${textColor}">${options.stopInfo.time_to_deliver}</text>
          </g>`

          if (options.stopInfo.order_type === 'Regular' || !options.stopInfo.time_to_deliver) {
            delivery_time = ''
          }
        }
        marker = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg xmlns="http://www.w3.org/2000/svg" width="121" height="56" viewBox="0 0 121 56" fill="none">

            <path d="M23.5 12.4925C23.5 15.3171 22.3082 19.7442 20.5086 24.3246C18.7146 28.8906 16.3468 33.5331 14.0509 36.7757C13.013 38.2415 11.0069 38.2325 9.97414 
              36.7573C7.7012 33.5107 5.32705 28.8702 3.5211 24.3091C1.70908 19.7326 0.5 15.3128 0.5 12.4925C0.5 5.85008 5.66749 0.5 12 0.5C18.3325 0.5 23.5 5.85008 23.5 12.4925Z" 
              fill="${color}" stroke="${stroke_color}" style="transform: translate(${x}px, 0px)"
            />

            <text font-weight="bold" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="16" y="20.891926" x="${markX}" fill="${textColor}">${stop_num}</text>
            ${delivery_time}
          </svg>`
      } else if ('img' in options) {
        iconUrl = options.img
      }
      L.extend(options, {
        shadowUrl: `${L.Icon.Default.imagePath}marker-shadow.png`,
      })
      L.setOptions(this, options)
      if (!iconUrl) {
        if (marker) {
          iconUrl = `data:image/svg+xml;base64,${btoa(marker)}`
        } else {
          iconUrl = ''
        }
      }
      if (typeof this.options.text === 'undefined' || this.options.text === '') {
        this._iconImg = this._createImg(iconUrl)
      }
    },
    createIcon() {
      let textDiv
      if (typeof this.options.text !== 'undefined' && this.options.text !== '') {
        textDiv = document.createElement('div')
        textDiv.className = 'icon-text'
        textDiv.innerHTML = this.options.text || ''
      }

      const div = document.createElement('div')

      if ((typeof this.options.text === 'undefined' || this.options.text === '') && typeof this.options.img === 'undefined') {
        div.appendChild(this._iconImg)
      }

      if (textDiv && typeof this.options.text !== 'undefined' && this.options.text !== '') {
        div.appendChild(textDiv)
      }

      if (typeof this.options.img !== 'undefined') {
        const imgDiv = document.createElement('div')
        imgDiv.className = 'icon-img'
        imgDiv.innerHTML = `<div class="pin" style="background: url('${this.options.img}') !important; background-position: center !important; background-size: 24px 24px !important;
            background-repeat: no-repeat !important;"></div><div class="pin-effect"></div>`

        div.appendChild(imgDiv)
      }

      this._setIconStyles(div, 'icon')
      if (typeof this.options.text !== 'undefined' && this.options.text !== '') {
        this._textDiv = textDiv
      }
      return div
    },
    setText(text) {
      this._textDiv.innerHTML = text || ''
    },
  })
})()
