import * as actions from './actions.js'
import { setItem } from '@/helper.js'

const state = {
  needCaptcha: undefined,
}

export default {
  actions,
  state,
  getters: {
    needCaptcha: state => {
      if (state.needCaptcha === undefined) {
        setItem(state, 'needCaptcha', false)
        actions.checkCaptcha()
          .then(need => { setItem(state, 'needCaptcha', need) })
          .catch(() => { setItem(state, 'needCaptcha', Infinity) })
      }
      return state.needCaptcha
    },
  },
  mutations: {
    needCaptcha(state, need) {
      setItem(state, 'needCaptcha', need)
    },
  },
  namespaced: true,
}
