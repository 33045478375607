import * as actions from './actions.js'
import { useStore } from '@/store'

import * as Store from '@/utils/store.js'

const state = {
  csvImportProfilesRecipient: null,
  ...Store.session({
    selectedCsvImportProfileRecipient: null,
  }),
}

export default {
  state,
  actions,
  getters: {
    selectedCsvImportProfileRecipient: stage => stage.selectedCsvImportProfileRecipient,
    csvImportProfilesRecipient: stage => {
      if (!stage.csvImportProfilesRecipient) {
        actions.loadCsvImportProfilesRecipient(useStore())
          .then(list => { stage.csvImportProfilesRecipient = list })
      }
      return stage.csvImportProfilesRecipient
    },
  },
  mutations: {
    selectedCsvImportProfileRecipient(state, value) {
      Store.setItem(state, 'selectedCsvImportProfileRecipient', value)
    },
  },
  namespaced: true,
}
