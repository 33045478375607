import axios from 'axios'

export const getDepartments = ({ commit }, { search, filters, order }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDepartments.php', { search, filters, order }, { params: { action: 'getList' } })
    .then(({ data: { departments, types } }) => {
      commit('SET_DEPARTMENTS', departments)
      commit('SET_TYPES', types)
      resolve(departments)
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const createDepartment = ({ commit }, department) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDepartments.php', { department }, { params: { action: 'createDepartment' } })
    .then(id => {
      resolve(id)
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const editDepartment = ({ commit }, department) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDepartments.php', { department }, { params: { action: 'editDepartment' } })
    .then(() => {
      resolve()
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const deleteDepartment = ({ commit }, departmentId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullDepartments.php', { departmentId }, { params: { action: 'deleteDepartment' } })
    .then(() => {
      resolve()
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const validateDepartment = ({ commit }, department) => {
  let isValid = true

  if (!department.name) {
    commit('SET_ERROR', { key: 'name', error: 'Department Name is required' })
    isValid = false
  } else {
    commit('SET_ERROR', { key: 'name', error: '' })
  }

  if (!department.type) {
    commit('SET_ERROR', { key: 'type', error: 'Department Type is required' })
    isValid = false
  } else {
    commit('SET_ERROR', { key: 'type', error: '' })
  }

  return isValid
}
