import axios from 'axios'

export const getClientsBySource = request => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClients.php', { request }, { params: { action: 'getClientsBySource' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getClients = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', {}, { params: { action: 'getClients' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
