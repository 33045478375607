import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'
import * as Store from '@/utils/store.js'

const state = {
  checkDelivery: {},
  rates: [],
  ...Store.session({
    approveMethods: undefined,
    documents: {},
    subtypes: undefined,
    defaultOrder: {},
    servicesList: {},
  }),
}

export default {
  actions,
  state,
  getters: {
    servicesList: state => clientId => {
      if (!clientId) return {}
      if (typeof state.servicesList[clientId] === 'undefined') {
        setItem(state.servicesList, clientId, { reload: true })
      }

      if (state.servicesList[clientId]?.reload) {
        state.servicesList[clientId].reload = false
        actions.getServicesList(useStore(), { clientId })
          .then(services => { services.reload = false; setItem(state.servicesList, clientId, services) })
          .catch(() => { setItem(state.servicesList, clientId, Infinity) })
      }
      return state.servicesList[clientId]
    },
    defaultOrder: state => clientId => {
      if (!clientId) return {}
      if (typeof state.defaultOrder[clientId] === 'undefined') {
        setItem(state.defaultOrder, clientId, { reload: true })
      }

      if (state.defaultOrder[clientId]?.reload) {
        state.defaultOrder[clientId].reload = false
        actions.getDefaultOrder(useStore(), { clientId })
          .then(order => { setItem(state.defaultOrder, clientId, order) })
          .catch(() => { setItem(state.defaultOrder, clientId, Infinity) })
      }
      return state.defaultOrder[clientId]
    },
    checkDelivery: state => ({ clientId, lat, lng }) => {
      if (!clientId || !lat || !lng) return {}
      if (typeof state.checkDelivery[clientId] === 'undefined') {
        setItem(state.checkDelivery, clientId, {})
      }
      const key = `${lat};${lng}`
      if (typeof state.checkDelivery[clientId][key] === 'undefined') {
        setItem(state.checkDelivery[clientId], key, { reload: true })
      }

      if (state.checkDelivery[clientId][key]?.reload) {
        state.checkDelivery[clientId][key].reload = false
        actions.checkDelivery(useStore(), { clientId, lat, lng })
          .then(types => { setItem(state.checkDelivery[clientId], key, types) })
          .catch(() => { setItem(state.checkDelivery[clientId], key, Infinity) })
      }
      return state.checkDelivery?.[clientId]?.[key] || {}
    },
    documents: state => clientId => {
      if (!clientId) return undefined
      if (typeof state.documents[clientId] === 'undefined') {
        actions.getDocumentsToSign(useStore(), { clientId })
          .then(documents => { setItem(state.documents, clientId, documents) })
          .catch(() => { setItem(state.documents, clientId, Infinity) })
      }
      return state.documents[clientId]
    },
    approveMethods(state) {
      if (state.approveMethods === undefined) {
        actions.getApproveMethods(useStore(), {})
          .then(methods => { state.approveMethods = methods })
          .catch(() => { state.approveMethods = Infinity })
      }
      return state.approveMethods
    },
    subtypes: state => {
      if (state.subtypes === undefined) {
        actions.getSubTypes(useStore(), { })
          .then(subtypes => {
            setItem(state, 'subtypes', subtypes)
          })
          .catch(() => { setItem(state, 'subtypes', Infinity) })
      }
      return state.subtypes
    },
    rates: state => state.rates,
  },
  mutations: {
    setRates(store, items) {
      setItem(store, 'rates', items)
    },
  },
  namespaced: true,
}
