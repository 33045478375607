import * as actions from './actions.js'

import { setItem, unsetItem } from '@/helper.js'

const state = {
  applyDriver: null,
  drivers: {},
}

export default {
  actions,
  state,
  getters: {
    applyDriver: state => state.applyDriver,
    drivers: stage => stage.drivers,
    driversById: stage => uid => {
      if (stage.drivers && uid in stage.drivers) { return stage.drivers[uid] }
      return null
    },
  },
  mutations: {
    CLEAR_APPLY_DRIVER(state) {
      unsetItem(state, 'applyDriver')
    },
    SET_APPLY_DRIVER(state, user) {
      setItem(state, 'applyDriver', user)
    },
    ADD_DRIVER(state, { uid, wait, user }) {
      if (!(uid in state.drivers) && typeof wait !== 'undefined') {
        setItem(state.drivers, uid, wait)
      } else if (typeof user !== 'undefined') {
        setItem(state.drivers, uid, user)
      }
    },
  },
  namespaced: true,
}
