import _ from 'lodash'
///* <vue2>
import Vuex from 'vuex'
// </vue2> */
/* <vue3>
import { createStore } from 'vuex'
// </vue3> */

import Auth from '@/store/Auth'
import Common from '@/store/Common'
import OrderTypes from '@/store/OrderTypes'
import Apply from '@/store/Apply'
import Notes from '@/store/Notes'
import Orders from '@/store/Orders'
import Routes from '@/store/Routes'
import Equipments from '@/store/Equipment'
import Departments from '@/store/Departments'
import ModalNotifications from '@/store/ModalNotifications'
import Tickets from '@/store/Tickets'
import UsersGlobal from '@/store/Users'
import Users from '@/store/Users/namespaced.js'
import Clients from '@/store/Clients'
import Qaqc from '@/store/Qaqc'
import Call from '@/store/Call'
import AddressBook from '@/store/AddressBook'
import Map from '@/store/Map'
import Finances from '@/store/Finances'
import Scan from '@/store/Scan'
import PrimeRxOrders from '@/store/PrimeRxOrders'
import IntegrationStatistics from '@/store/IntegrationStatistics'
import Account from '@/store/Account'
import Rx4RouteInstruction from '@/store/Rx4RouteInstruction/index'
import Favorites from '@/store/Favorites/index'
import Facilities from '@/store/Facilities'
import DispatcherQueue from '@/store/DispatcherQueue'
import DispatcherQueueAnalytics from '@/store/DispatcherQueueAnalytics'
import FinancesRx4Route from '@/store/FinancesRx4Route'

import * as Store from '@/utils/store.js'
import { setItem } from '@/helper.js'

const cookie = document.cookie.split(/[;] */).reduce((result, pairStr) => {
  const arr = pairStr.split('=')
  if (arr.length === 2) { result[arr[0]] = arr[1] }
  return result
}, {})

const state = {
  authToken: 'usession' in cookie ? cookie.usession : null,
  ...Store.local({
    messagesList: [],
    messagesAwait: 30,
    mutedFridge: false,
    galleryScale: 1,
    showColumns: {},
  }),
  loading: false,
}

const curObject = {
  state,
  getters: {
    authToken: stage => stage.authToken,
    messagesList: stage => stage.messagesList,
    messagesAwait: stage => stage.messagesAwait,
    mutedFridge: stage => stage.mutedFridge,
    galleryScale: stage => stage.galleryScale,
    loading: stage => stage.loading,
    showColumns: stage => page => {
      if (!(page in stage.showColumns)) {
        setItem(state.showColumns, page, [])
        Store.setItem(state, 'showColumns', state.showColumns)
      }
      return stage.showColumns[page]
    },
  },
  mutations: {
    showColumns(state, payload) {
      const tmp = state.showColumns
      _.map(payload, (v, k) => {
        state.showColumns[k] = v
      })
      Store.setItem(state, 'showColumns', tmp)
    },
    setAuthToken(state, token) {
      state.authToken = token
    },
    STORE_DATA(state, payload) {
      Store.setItem(state, payload.key, payload.value)
    },
    galleryScale(state, scale) {
      Store.setItem(state, 'galleryScale', scale)
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  modules: {
    Auth,
    Apply,
    Notes,
    Orders,
    Routes,
    Equipments,
    Departments,
    ModalNotifications,
    UsersGlobal,
    Users,
    Clients,
    Call,
    Qaqc,
    Map,
    AddressBook,
    Tickets,
    Common,
    Scan,
    Finances,
    PrimeRxOrders,
    IntegrationStatistics,
    Rx4RouteInstruction,
    Favorites,
    Facilities,
    Account,
    DispatcherQueue,
    DispatcherQueueAnalytics,
    OrderTypes,
    FinancesRx4Route,
  },
}
///* <vue2>
const cur = {
  vuex: null,
}
const store = () => {
  cur.vuex = new Vuex.Store(curObject)
  return cur.vuex
}

store.usable = Vuex
store.module = 'store'
// </vue2> */
/* <vue3>
const store = createStore(curObject)
// </vue3> */

export function useStore() {
  ///* <vue2>
  return cur.vuex
  // </vue2> */
  /* <vue3>
  return store
  // </vue3> */
}

export default store
