import * as actions from './actions'

const state = {
  list: {},
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    list: state => state.list,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload/* ?.map(item => ({
        ...item,
        created_at: makeDate(item.created_at),
        started_at: makeDate(item.started_at),
        finished_at: makeDate(item.finished_at),
      })) */
    },
  },
}
