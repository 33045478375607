export const stopTimer = context => {
  const intervalId = context.state.timerIntervalId
  if (intervalId) {
    clearInterval(intervalId)
    context.commit('setTimerIntervalId', null)
    context.commit('setTimer', 0)
  }
}

export const startTimer = context => {
  stopTimer(context)

  let seconds = 0
  const intervalId = setInterval(() => {
    context.commit('setTimer', seconds += 1)
  }, 1000)

  context.commit('setTimerIntervalId', intervalId)
}
