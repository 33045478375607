import axios from 'axios'
import { apiV1 } from '@/config.js'

export const loadNachaTransactions = ({ commit }, { page, per_page, from, to, client }) => new Promise((resolve, reject) => {
  commit('START_LOAD', { page, per_page, from, to })
  axios.get(`${apiV1}/reports/transactions-report`, { params: { from, to, page, per_page, client } })
    .then(({ data }) => {
      const preparedData = {
        ...data.pagination,
        items: [...data.list],
      }
      commit('SET_ITEMS', Object.assign(preparedData, { from, to, page, per_page, client }))
    })
})

export const change = ({ commit, state }, data) => new Promise((resolve, reject) => {
  const newState = Object.assign(state, data)
  loadNachaTransactions({ commit }, newState)
})
