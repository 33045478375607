import Dispatchers from './Dispatchers'
import Tickets from './Tickets'

const state = {
  view: 'table',
  filter: {
    date: null,
    search: null,
    order_type: null,
  },
  counts: {
    orderTypes: {},
  },
}

export default {
  namespaced: true,
  modules: {
    Dispatchers,
    Tickets,
  },
  state,
  getters: {
    view: state => state.view,
    filter: state => state.filter,
    counts: state => state.counts,
  },
  mutations: {
    setView(state, payload) {
      state.view = payload
    },
    setFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'date')) {
        state.filter.date = payload.date
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'search')) {
        state.filter.search = payload.search
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'orderType')) {
        state.filter.order_type = payload.orderType === 'all' ? null : payload.orderType
      }
    },
    setCounts(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'orderTypes')) {
        state.counts.orderTypes = payload.orderTypes
      }
    },
  },
}
