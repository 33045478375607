import StripeTransactions from './StripeTransactions'
import Common from './Common'

export default {
  modules: {
    Common,
    StripeTransactions,
  },
  namespaced: true,
}
