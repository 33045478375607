import axios from 'axios'

export const assignCallToMe = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQaqc.php', post, { params: { action: 'assignCallToMe' } })
    .then(({ data: { call } }) => resolve(call))
    .catch(e => reject(e))
})

export const unassignCalls = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQaqc.php', { }, { params: { action: 'unassignCalls' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const finishCall = ({ commit }, post) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQaqc.php', post, { params: { action: 'finishCall' } })
    .then(() => resolve())
    .catch(e => reject(e))
})

export const getNext = ({ commit }, group) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCallsQaqc.php', { group }, { params: { action: 'getNext' } })
    .then(({ data: { call } }) => resolve(call))
    .catch(e => reject(e))
})
