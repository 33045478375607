import axios from 'axios'

export const getHubs = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFacilityHub.php', {}, { params: { action: 'getHubs' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getClients = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', {}, { params: { action: 'getClients' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getPadeStatistics = filters => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFinanceStatistics.php', { filters }, { params: { action: 'getPadeStatistics' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
