const required = (message = null) => v => !!v || message || 'Is required'

const max = (len, message = null) => v => (!!v && String(v).length <= len) || !v || message || `Must be less than ${len} characters`
const min = (len, message = null) => v => (!!v && String(v).length >= len) || message || `Min ${len} characters`
const trimMin = (len, message = null) => v => (!!String(v).trim() && String(v).trim().length >= len) || message || `Min ${len} characters`
const size = (len, message = null) => v => (!!v && String(v).length !== len) || !v || message || `Size must be ${len} characters`

const minNumber = (len, message = null) => v => {
  len = Number(len)
  return Number(v) >= len
    || message
    || `Allow min value is ${len}`
}
const maxNumber = (len, message = null) => v => {
  len = Number(len)
  return len === 0 // maxNumber = 0 will not check
    || Number(v) <= len
    || message
    || `Allow max value is ${len}`
}

const timeValidationFormat = 'hh:mm A'

const minTime = (minTime, message = null) => v => {
  const momentTime = moment(v, timeValidationFormat)
  const momentTimeMin = moment(minTime, timeValidationFormat)
  return (momentTime.isAfter(momentTimeMin) || momentTime.isSame(momentTimeMin))
    || message
    || `minmum allowed time - ${minTime}`
}

const maxTime = (maxTime, message = null) => v => {
  const momentTime = moment(v, timeValidationFormat)
  const momentTimeMax = moment(maxTime, timeValidationFormat)

  return (momentTime.isBefore(momentTimeMax) || momentTime.isSame(momentTimeMax))
    || message
    || `maximum allowed time - ${maxTime}`
}

const trim = (message = null) => v => (!!v && String(v) === String(v).trim()) || !v || message || 'Not space are allowed started or ended'
const match = (pattern, message = null, required = true) => v => (!v && !required) || (!!v && (new RegExp(pattern)).test(String(v))) || message || `Must be matched ${pattern}`
const email = (message = null, required = false) => match('^.+@.+\\..+$', message || 'Must be email', required)
const compare = (comp, message = null) => v => (!!v && v) === comp || message || 'Do not match' //  `Do not match #${comp}#`

const url = (message = null, required = false) => match(
  'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
  message || 'Must be url',
  required,
)

export default {
  required,
  max,
  min,
  size,
  trim,
  trimMin,
  match,
  email,
  compare,
  minNumber,
  maxNumber,
  url,
  minTime,
  maxTime,
}
