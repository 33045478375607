import * as actions from './actions.js'
import RegionManage from './RegionManage/index.js'

export default {
  actions,
  modules: {
    RegionManage,
  },
  namespaced: true,
}
