import * as Store from '@/utils/store.js'
import * as actions from './actions.js'

const state = {
  ...Store.local({
    allowIncoming: false,
    callProvider: 'call-twilio',
  }),
}

export default {
  state,
  actions,
  getters: {
    allowIncoming: stage => stage.allowIncoming,
    callProvider: stage => stage.callProvider,
  },
  mutations: {
    allowIncoming(state, value) {
      Store.setItem(state, 'allowIncoming', value)
    },
    callProvider(state, value) {
      Store.setItem(state, 'callProvider', value)
    },
  },
  namespaced: true,
}
