import * as actions from './actions.js'
import { useStore } from '@/store'

import * as Store from '@/utils/store.js'

const state = {
  ...Store.local({
    scroll: true,
    showList: true,
    activityFilter: 'all',
  }),
  ...Store.session({
    fields: undefined,
  }),
}

export default {
  actions,
  state,
  getters: {
    scroll: stage => stage.scroll,
    showList: stage => stage.showList,
    activityFilter: stage => stage.activityFilter,
    fields: stage => {
      if (state.fields === undefined) {
        state.fields = []
        actions.getQaqcFields(useStore(), {})
          .then(fields => { Store.setItem(state, 'fields', fields) })
          .catch(() => { state.fields = Infinity })
      }
      return state.fields
    },
  },
  mutations: {
    scroll(state, value) {
      Store.setItem(state, 'scroll', value)
    },
    showList(state, value) {
      Store.setItem(state, 'showList', value)
    },
    activityFilter(state, value) {
      Store.setItem(state, 'activityFilter', value)
    },
  },
  namespaced: true,
}
