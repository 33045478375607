import * as actions from './actions.js'

const state = {
  clients: null,
  sources: null,
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    clients: state => state.clients,
    sources: state => state.sources,
  },
  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_SOURCES(state, sources) {
      state.sources = sources
    },
  },
}
