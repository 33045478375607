/* eslint-disable import/prefer-default-export */

import axios from 'axios'
import { apiV1 } from '@/config'
import { useStore } from '@/store'

export const getList = async context => {
  const store = useStore()
  const params = {
    ...context.rootGetters['DispatcherQueueAnalytics/filter'],
    sort: {
      ...context.state.sort,
    },
  }
  const { data: request } = await axios.get(`${apiV1}/dispatcher/stop-allow/dispatchers`, { params })

  context.commit('setList', request.data)
  store.commit('DispatcherQueueAnalytics/setCounts', request.counts)
}
