import * as actions from './actions.js'
import * as Store from '@/utils/store.js'

import { setItem } from '@/helper.js'

const state = {
  ...Store.session({
    replies: null,
    timeList: null,
    timeWindow: null,
  }),
}

export default {
  actions,
  state,
  getters: {
    replies: state => {
      if (state.replies === null) {
        setItem(state, 'replies', {})
        actions.getReplies()
          .then(replies => { Store.setItem(state, 'replies', replies) })
          .catch(() => { setItem(state, 'replies', Infinity) })
      }
      return state.replies
    },
    timeList: state => {
      if (state.timeList === null) {
        setItem(state, 'timeList', [])
        actions.getTimeList()
          .then(list => { Store.setItem(state, 'timeList', list) })
          .catch(() => { setItem(state, 'replies', Infinity) })
      }
      return state.timeList
    },
    timeWindow: state => {
      if (state.timeWindow === null) {
        setItem(state, 'timeWindow', [])
        actions.getTimeWindow()
          .then(window => { Store.setItem(state, 'timeWindow', window) })
          .catch(() => { setItem(state, 'timeWindow', Infinity) })
      }
      return state.timeWindow
    },
  },
  namespaced: true,
}
