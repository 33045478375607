import * as actions from './actions.js'
import * as Store from '@/utils/store.js'
import { useStore } from '@/store'

const state = {
  ...Store.session({
    deliveryTypes: undefined,
    deliverySubTypes: undefined,
  }),
}

export default {
  actions,
  state,
  getters: {
    deliveryTypes(state) {
      if (state.deliveryTypes === undefined) {
        state.deliveryTypes = []
        actions.getAllTypes(useStore(), {})
          .then(types => { Store.setItem(state, 'deliveryTypes', types) })
      }
      return state.deliveryTypes
    },
    deliverySubTypes(state) {
      if (state.deliverySubTypes === undefined) {
        state.deliverySubTypes = []
        actions.getAllSubTypes(useStore(), {})
          .then(subTypes => { Store.setItem(state, 'deliverySubTypes', subTypes) })
      }
      return state.deliverySubTypes
    },
  },
  namespaced: true,
}
