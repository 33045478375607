import axios from 'axios'
import { apiV1 } from '@/config'
import { useStore } from '@/store'

export const getList = async context => {
  const store = useStore()
  const params = {
    ...context.rootGetters['DispatcherQueueAnalytics/filter'],
    ...context.state.filter,
    sort: {
      ...context.state.sort,
    },
  }
  const { data: request } = await axios.get(`${apiV1}/dispatcher/stop-allow/list`, { params })

  context.commit('setList', request.data)
  context.commit('setSummary', request.summary)
  context.commit('setCounts', request.counts)
  store.commit('DispatcherQueueAnalytics/setCounts', request.counts)
}

export const getHistory = async (context, stopId) => {
  const { data: request } = await axios.get(`${apiV1}/dispatcher/stop-allow/history/${stopId}`)

  context.commit('setHistory', request.data)
}
