import axios from 'axios'

export const getAllTypes = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrderTypes.php', {}, { params: { action: 'getAllTypes' } })
    .then(({ data: { types } }) => {
      resolve(types)
    })
    .catch(error => {
      reject(error)
    })
})
export const getAllSubTypes = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrderTypes.php', {}, { params: { action: 'getAllSubTypes' } })
    .then(({ data: { subTypes } }) => {
      resolve(subTypes)
    })
    .catch(error => {
      reject(error)
    })
})
