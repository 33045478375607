import axios from 'axios'

export const getCalendarEvents = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCalendar.php', {}, { params: { action: 'getCalendarEvents' }, toast: 'Loading Calendar events, wait..' })
    .then(({ data }) => {
      commit('SET_CALENDAR_EVENTS', data.events)
      resolve(data.events)
    })
    .catch(error => {
      reject(error)
    })
})

export const setSchedulingEnable = ({ commit }, enabled) => axios.post('/ajax/PullCalendar.php', { enabled }, { params: { action: 'setSchedulingEnable' }, toast: 'Saving changing, wait..' })
  .then(({ data }) => data.enabled)
