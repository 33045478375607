import * as actions from './actions.js'

const state = {
  showModal: false,
  steps: [],
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    showModal: state => state.showModal,
    steps: state => state.steps,
  },
  mutations: {
    SET_SHOW_MODAL(state, showModal) {
      state.showModal = showModal
    },
    SET_STEPS(state, steps) {
      state.steps = steps
    },
  },
}
