import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'

const state = {
  userApiTokens: {},
}

export default {
  actions,
  state,
  getters: {
    userApiTokens: stage => userId => {
      if (!(userId in stage.userApiTokens)) {
        setItem(stage.userApiTokens, userId, null)
      }

      if (stage.userApiTokens[userId] === null) {
        setItem(stage.userApiTokens, userId, undefined)
        actions.loadUserApiTokens(useStore(), { userId }).then(tokens => {
          setItem(stage.userApiTokens, userId, tokens)
        })
      }
      return stage.userApiTokens[userId]
    },
  },
  mutations: {
    CREATED_USER_API_TOKEN(state, { token, userId }) {
      setItem(state.userApiTokens, userId, [...state.userApiTokens[userId], token])
    },
    DELETED_USER_API_TOKEN(state, { uid, userId }) {
      setItem(state.userApiTokens, userId, state.userApiTokens[userId].filter(apiTokenData => apiTokenData.uid !== uid))
    },
  },
  namespaced: true,
}
