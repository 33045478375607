import * as actions from './actions.js'

import * as Store from '@/utils/store.js'

const state = {
  ...Store.local({
    showQueue: true,
    listScroll: true,
    callQueueGroup: 'all',
    callQueueSubGroup: 'all',
  }),
}

export default {
  actions,
  state,
  getters: {
    showQueue: stage => stage.showQueue,
    listScroll: stage => stage.listScroll,
    callQueueGroup: stage => stage.callQueueGroup,
    callQueueSubGroup: stage => stage.callQueueSubGroup,
  },
  mutations: {
    showQueue(state, value) {
      Store.setItem(state, 'showQueue', value)
    },
    listScroll(state, value) {
      Store.setItem(state, 'listScroll', value)
    },
    callQueueGroup(state, value) {
      Store.setItem(state, 'callQueueGroup', value)
    },
    callQueueSubGroup(state, value) {
      Store.setItem(state, 'callQueueSubGroup', value)
    },
  },
  namespaced: true,
}
