import * as actions from './actions.js'

import { setItem, unsetItem } from '@/helper.js'

const state = {
  notifications: {},
  notificationErrors: {
    title: '',
    message_text: '',
    show_seconds: '',
    expired_in_min: '',
    type: '',
    department: '',
  },
  notification: {
    uid: 0,
    title: '',
    message_text: '',
    show_seconds: null,
    expired_in_min: null,
    types: {},
    departments: {},
  },
  notificationReceivers: {},
}

export default {
  actions,
  state,
  getters: {
    notifications: state => state.notifications,
    notification: state => state.notification,
    notificationErrors: state => state.notificationErrors,
    notificationReceivers: state => state.notificationReceivers,
  },
  mutations: {
    CLEAR_NOTIFICATIONS(state) {
      unsetItem(state, 'notifications')
    },
    SET_NOTIFICATIONS(state, types) {
      setItem(state, 'notifications', types)
    },
    SET_ERROR(state, payload) {
      state.notificationErrors[payload.key] = payload.error
    },
    CLEAR_ERROR(state, key) {
      state.notificationErrors[key] = ''
    },
    CLEAR_ERRORS(state) {
      Object.keys(state.notificationErrors).forEach(key => {
        state.notificationErrors[key] = ''
      })
    },
    SET_RECEIVERS(state, notificationReceivers) {
      setItem(state, 'notificationReceivers', notificationReceivers)
    },
    CLEAR_RECEIVERS(state) {
      unsetItem(state, 'notificationReceivers')
    },
  },
}
