import axios from 'axios'

export const getRouteNames = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullRouteNames.php', { params: { action: 'getRouteNames' } })
    .then(({ data: { routeNames } }) => resolve(routeNames))
    .catch(e => reject(e))
})

export const getRouteNameGroups = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullRouteNames.php', { params: { action: 'getRouteNameGroups' } })
    .then(({ data: { routeNameGroups } }) => resolve(routeNameGroups))
    .catch(e => reject(e))
})
