import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'

const state = {
  regions: undefined,
  clientRegions: {},
}

export default {
  actions,
  state,
  getters: {
    clientRegions: state => (clientId, typeId) => {
      if (!clientId || !typeId) return undefined
      if (typeof state.clientRegions[clientId] === 'undefined') {
        setItem(state.clientRegions, clientId, {})
      }

      if (typeof state.clientRegions[clientId][typeId] === 'undefined' && state.clientRegions[clientId] !== Infinity) {
        setItem(state.clientRegions[clientId], typeId, {})
        actions.getMyRegions(useStore(), { clientId, typeId })
          .then(regions => {
            setItem(state.clientRegions[clientId], typeId, regions)
          })
          .catch(() => { state.clientRegions[clientId][typeId] = Infinity })
      }
      return state.clientRegions[clientId][typeId]
    },
    regions(state) {
      if (state.regions === undefined) {
        actions.getRegions(useStore(), {})
          .then(regions => { state.regions = regions })
      }
      return state.regions
    },
  },
  mutations: {
    reloadRegions(state) {
      actions.getRegions(useStore(), {})
        .then(regions => { state.regions = regions })
    },
  },
  namespaced: true,
}
