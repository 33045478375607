import Calendar from './Calendar/index.js'
import Drivers from './Drivers/index.js'

export default {
  modules: {
    Calendar,
    Drivers,
  },
  namespaced: true,
}
