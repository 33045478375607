import * as actions from './actions.js'

import { setItem, unsetItem } from '@/helper.js'
import * as Store from '@/utils/store.js'

const state = {
  ...Store.local({
    scroll: true,
    showList: true,
  }),
  ticketsTypes: {},
  ticketsUsers: {},
  ...Store.session({
    fields: undefined,
  }),
}

export default {
  actions,
  state,
  getters: {
    scroll: stage => stage.scroll,
    showList: stage => stage.showList,

    ticketsTypes: state => state.ticketsTypes,
    ticketsUsers: state => state.ticketsUsers,
  },
  mutations: {
    scroll(state, value) {
      Store.setItem(state, 'scroll', value)
    },
    showList(state, value) {
      Store.setItem(state, 'showList', value)
    },
    CLEAR_TYPES(state) {
      unsetItem(state, 'ticketsTypes')
    },
    CLEAR_USERS(state) {
      unsetItem(state, 'ticketsUsers')
    },
    SET_TYPES(state, types) {
      setItem(state, 'ticketsTypes', types)
    },
    SET_USERS(state, types) {
      setItem(state, 'ticketsUsers', types)
    },
  },
  namespaced: true,
}
