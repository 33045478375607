import axios from 'axios'

export const loadTicketTypes = ({ commit }, filters) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullTickets.php', { filters }, { params: { action: 'getTypes' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      commit('CLEAR_TYPES')
      reject(error)
    })
})

export const loadTicketUsers = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullTickets.php', {}, { params: { action: 'getUsers' } })
    .then(({ data: { users } }) => {
      commit('SET_USERS', users)
      resolve(users)
    })
    .catch(error => {
      commit('CLEAR_USERS')
      reject(error)
    })
})

export const addEquipmentType = ({ commit }, { title }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullTickets.php', { title }, { params: { action: 'addTicketType' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      reject(error)
    })
})

export const removeEquipmentType = ({ commit }, { slug }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { slug }, { params: { action: 'removeEquipmentType' } })
    .then(({ data: { types } }) => {
      commit('SET_TYPES', types)
      resolve(types)
    })
    .catch(error => {
      reject(error)
    })
})

export const calcCountsEquipment = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { group: 'type' }, { params: { action: 'calcCounts' } })
    .then(({ data: { counts } }) => {
      resolve(counts)
    })
    .catch(error => {
      reject(error)
    })
})

export const createEquipment = ({ commit }, { slug, num }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { slug, num }, { params: { action: 'createEquipment' } })
    .then(({ data }) => {
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})

export const confirmPrintedEquipment = ({ commit }, { code }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullEquipments.php', { code }, { params: { action: 'confirmPrintedEquipment' } })
    .then(({ data }) => {
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})
