/* eslint-disable no-param-reassign */
import { useStore } from '@/store'

/**
 * @param  {Array} routes
 * @param  {Function} guard
 * @return {Array}
 */
function guard(routes, callback) {
  routes.forEach(route => {
    route.beforeEnter = callback
  })
  return routes
}

/**
 * Add the "authenticated" guard.
 *
 * @param  {Array} routes
 * @return {Array}
 */
export function authGuard(routes) {
  return guard(routes, (to, from, next) => {
    if (useStore().getters.authToken) {
      next()
    } else {
      next({ name: 'auth-login' })
    }
  })
}

/**
 * Add the "guest" guard.
 *
 * @param  {Array} routes
 * @return {Array}
 */
export function guestGuard(routes, condition) {
  return guard(condition() ? routes : [], (to, from, next) => {
    if (useStore().getters.authToken) {
      document.location = '/orders'
      next({ name: 'orders' })
    } else {
      next()
    }
  })
}
