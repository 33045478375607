import _ from 'lodash'
import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem, pushItem, checkAndRemoveId } from '@/helper.js'
import * as Store from '@/utils/store.js'

const state = {
  relocates: [],
  facilities: null,
  facilityHubs: {},
  authUser: null,
  clients: {},
  userProfile: {},
  userDocs: {},
  ...Store.pouchdb({
    publicUsers: {},
  }),
  ...Store.session({
    returnDebug: false,
  }),
  ...Store.local({
    voiceSpeak: 'US English Female',
    voiceRate: 1,
    themeDark: false,
  }),
}

export default {
  actions,
  state,
  getters: {
    relocates: state => state.relocates,
    facilities: state => {
      if (state.facilities === null) {
        setItem(state, 'facilities', {})
        actions.getFacilities()
          .then(facilities => { _.map(facilities, item => setItem(state.facilities, item.uid, item)) })
          .catch(() => { setItem(state, 'facilities', Infinity) })
      }
      return state.facilities ?? []
    },
    returnDebug: state => state.returnDebug,
    themeDark: state => state.themeDark,
    voiceSpeak: state => state.voiceSpeak,
    voiceRate: state => state.voiceRate,

    facilityHubs: state => facilityId => {
      if (!(facilityId in state.facilityHubs)) {
        setItem(state.facilityHubs, 'reload', true)
        setItem(state.facilityHubs, facilityId, [])
      }

      if (state.facilityHubs?.reload) {
        setItem(state.facilityHubs, 'reload', false)

        actions.getAllHubs(useStore(), {})
          .then(hubs => {
            setItem(state.facilityHubs, facilityId, [])
            _.map(hubs, hub => {
              if (!(hub.facility in state.facilityHubs)) {
                setItem(state.facilityHubs, hub.facility, [])
              }
              pushItem(state.facilityHubs, hub.facility, hub)
            })
          })
      }
      return state.facilityHubs[facilityId]
    },
    currentUser: state => {
      if (state.authUser === Infinity) {
        return false
      }

      if (!state.authUser) {
        setItem(state, 'authUser', { reload: true, loading: false })
      }
      if (state.authUser?.reload && state.authUser && $.cookie && $.cookie('usession')) {
        state.authUser.reload = false
        state.authUser.loading = true
        useStore().dispatch('getCurrentUser', {})
          .then(user => {
            user.reload = false
            user.loading = false
            setItem(state, 'authUser', user)
          })
          .catch(() => setItem(state, 'authUser', Infinity))
      }
      return state.authUser
    },
    currentClient: state => clientId => {
      if (!state.authUser) return
      if (clientId === state.authUser.uid) return state.currentUser
      if (!(clientId in state.clients)) {
        setItem(state.clients, clientId, { reload: true, loading: false })
      }

      if (state.clients[clientId]?.reload) {
        state.clients[clientId].reload = false
        state.clients[clientId].loading = true
        useStore().dispatch('getCurrentUser', { clientId })
          .then(user => {
            user.reload = false
            user.loading = false
            setItem(state.clients, clientId, user)
          })
          .catch(() => setItem(state.clients, clientId, Infinity))
      }
      return state.clients[clientId]
    },

    publicUsers: state => userId => {
      const cache = 10 * 60 * 60 * 1000 // Кешируем данные на рабочий день
      if (!(userId in state.publicUsers)
      || ((!('saveDateTime' in state.publicUsers[userId]) || (new Date().getTime() - state.publicUsers[userId].saveDateTime) > cache) && !state.publicUsers[userId].reload)) {
        Store.pouchSet([state, 'publicUsers'], userId, { reload: true })
      }

      if (state.publicUsers[userId]?.reload) {
        state.publicUsers[userId].reload = false

        useStore().dispatch('getPublicUserInfo', { userId })
          .then(user => Store.pouchSet([state, 'publicUsers'], user.uid, user))
          .catch(() => Store.pouchSet([state, 'publicUsers'], userId, Infinity))
      }
      return state.publicUsers[userId]
    },
    getUserProfile: state => userId => {
      if (!(userId in state.userProfile)) {
        setItem(state.userProfile, userId, { reload: true })
      }
      if (state.userProfile[userId]?.reload) {
        state.userProfile[userId].reload = false
        useStore().dispatch('getUserProfile', { userId })
          .then(user => setItem(state.userProfile, userId, user))
          .catch(() => setItem(state.userProfile, userId, Infinity))
      }
      return state.userProfile[userId]
    },
    getUserDocs: state => userId => {
      if (!(userId in state.userDocs)) {
        setItem(state.userDocs, userId, { reload: true, list: [] })
      }
      if (state.userDocs[userId]?.reload) {
        state.userDocs[userId].reload = false
        useStore().dispatch('getUserDocs', { userId })
          .then(docs => setItem(state.userDocs[userId], 'list', docs))
          .catch(() => setItem(state.userDocs[userId], 'list', []))
      }
      return state.userDocs[userId]
    },
  },
  mutations: {
    SET_RELOCATED(state, relocates) {
      setItem(state, 'relocates', relocates)
    },
    SET_RETURN_DEBUG(state, value) {
      Store.setItem(state, 'returnDebug', value)
    },
    SET_THEME_DARK(state, value) {
      Store.setItem(state, 'themeDark', value)
    },
    voiceSpeak(state, value) {
      Store.setItem(state, 'voiceSpeak', value)
    },
    voiceRate(state, value) {
      Store.setItem(state, 'voiceRate', value)
    },
    'currentUser.facility': (state, facility) => {
      actions.setFacility(useStore(), { facility })
        .then(() => setTimeout(() => {
          window.location.href = checkAndRemoveId(window.location.pathname, '^\\/routes\\/\\d+$')
        }, 1500))
    },
    facilities(state, value) {
      state.facilities = value
    },
  },
}
