import * as actions from './actions'
import { makeDate } from '@/utils/date'

const state = {
  list: {},
  sort: {},
  filter: {
    request_type: null,
  },
  counts: {
    requestTypes: {},
  },
  summary: {
    created_started: {
      min: 0,
      max: 0,
      avg: 0,
    },
    started_finished: {
      min: 0,
      max: 0,
      avg: 0,
    },
    created_finished: {
      min: 0,
      max: 0,
      avg: 0,
    },
  },
  history: null,
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    list: state => state.list,
    sort: state => state.sort,
    filter: state => state.filter,
    counts: state => state.counts,
    summary: state => state.summary,
    history: state => state.history,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload?.map(item => ({
        ...item,
        created_at: makeDate(item.created_at),
        started_at: makeDate(item.started_at),
        finished_at: makeDate(item.finished_at),
      }))
    },
    setSort(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'field')) {
        state.sort.field = payload.field
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'direction')) {
        state.sort.direction = payload.direction
      }
    },
    setFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'requestType')) {
        state.filter.request_type = payload.requestType === 'all' ? null : payload.requestType
      }
    },
    setCounts(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'requestTypes')) {
        state.counts.requestTypes = payload.requestTypes
      }
    },
    setSummary(state, payload) {
      state.summary = payload
    },
    setHistory(state, payload) {
      state.history = payload?.map(item => ({
        ...item,
        created_at: makeDate(item.created_at),
        started_at: makeDate(item.started_at),
        finished_at: makeDate(item.finished_at),
      }))
    },
  },
}
