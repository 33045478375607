import * as actions from './actions'
import { useStore } from '@/store'
import { setItem } from '@/helper'

const state = {
  load: false,
  data: null,
  customFieldsForAdd: [],
  customFieldsForDelete: [],
  usersForAdd: [],
  usersForDelete: [],
}

export default {
  actions,
  state,
  getters: {
    data: (state, d, v) => {
      if (state.data === undefined) {
        return null
      }

      if (state.data === null) {
        useStore().dispatch('Finances/Reports/Common/loadReport')
        return null
      }

      return state.data
    },
    load: state => state.load,
    customFieldsForAdd: state => state.customFieldsForAdd,
  },
  mutations: {
    START_LOAD(state) {
      setItem(state, 'load', false)
    },
    SET_REPORTS(state, data) {
      setItem(state, 'data', data)
      setItem(state, 'load', false)
    },
    SET_USER_ADD(sate, data) {
      state.usersForAdd.push(data.uid)
      state.data.clients.push({
        uid: 0,
        client_uid: data.uid,
        name: data.business_name,
      })
      state.usersForDelete = state.usersForDelete.filter(clientUid => clientUid !== data.uid)
    },
    SET_USER_FOR_DELETE(state, uid) {
      state.data.clients = state.data.clients.filter(client => client.client_uid !== uid)
      state.usersForAdd = state.usersForAdd.filter(clientUid => clientUid !== uid)
      state.usersForDelete.push(uid)
    },
    CLEAR_USERS_CHANGES(state) {
      state.usersForAdd = []
      state.usersForDelete = []
    },
    DELETE_CUSTOM_FIELD(state, uid) {
      state.customFieldsForDelete.push(uid)
      state.data.fields = state.data.fields.filter(field => field.uid !== uid)
    },
    DELETE_TEMP_CUSTOM_FIELD(state, index) {
      state.customFieldsForAdd = state.customFieldsForAdd.filter((_, _index) => _index !== index)
    },
    ADD_CUSTOM_FIELD(state, data) {
      state.customFieldsForAdd.push(data)
    },
    CLEAR_TEMP_CUSTOM_FIELDS(state) {
      state.customFieldsForAdd = []
      state.customFieldsForDelete = []
    },
  },
  namespaced: true,
}
