import axios from 'axios'

export const primeRxOrdersCount = request => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', { request }, { params: { action: 'primeRxOrdersCount' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getUserData = userId => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'getUserProfile' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const setData = (store, orders) => {
  store.commit('PrimeRxOrders/SET_ORDERS_COUNT', orders)

  const deliveredCount = orders.reduce((acc, order) => acc + Number(order.delivered_orders_count), 0)
  store.commit('PrimeRxOrders/SET_TOTAL_DELIVERED', deliveredCount)

  const totalCount = orders.reduce((acc, order) => acc + Number(order.total_orders_count), 0)
  store.commit('PrimeRxOrders/SET_TOTAL_CREATED', totalCount)
}
