import _ from 'lodash'
import * as actions from './actions.js'
import { useStore } from '@/store'

import { setItem } from '@/helper.js'
import * as Store from '@/utils/store.js'

const state = {
  routeProfiles: {},
  routesAll: null,
  driversAll: null,
  stops: {},
  routes: {},
  autoRefresh: true,
  newTable: false,
  ...Store.local({
    overTimeView: true,
    notificationView: true,
  }),
  ...Store.session({
    states: undefined,
  }),
}

const getStatesList = stage => {
  if (stage.states === undefined) {
    Store.setItem(stage, 'states', { reload: true })
  }

  if (stage.states?.reload) {
    Store.setItem(stage, 'states', {})

    actions.getStates(useStore())
      .then(({ data: { states } }) => Store.setItem(stage, 'states', states))
      .catch(() => { Store.setItem(stage, 'states', Infinity) })
  }
  return stage.states
}

export default {
  actions,
  state,
  getters: {
    routeProfiles: state => state.routeProfiles,
    routeProfile: state => routeId => {
      if (!(routeId in state.routeProfiles)) {
        setItem(state.routeProfiles, routeId, { reload: true })
      }

      if (state.routeProfiles[routeId]?.reload) {
        state.routeProfiles[routeId].reload = false
        useStore().dispatch('Routes/getRouteProfile', { routeId })
          .then(route => setItem(state.routeProfiles, route.uid, route))
          .catch(() => setItem(state.routeProfiles, routeId, Infinity))
      }
      return state.routeProfiles[routeId]
    },

    overTimeView: stage => stage.overTimeView,
    notificationView: stage => stage.notificationView,
    routes: stage => {
      if (!stage.routesAll) {
        if (!state.routesAll) state.routesAll = {}
        actions.getRoutes(useStore())
          .then(routes => {
            _.map(routes, route => {
              setItem(state.routesAll, route.uid, route)
            })
          })
      }
      return stage.routesAll
    },
    drivers: stage => {
      if (!stage.driversAll) {
        if (!state.driversAll) state.driversAll = {}
        actions.getDrivers(useStore())
          .then(drivers => {
            _.map(drivers, driver => {
              setItem(state.driversAll, driver.uid, driver)
            })
          })
      }
      return stage.driversAll
    },

    routeById: stage => uid => {
      if (stage.routesAll && uid in stage.routesAll) { return stage.routesAll[uid] }
      return null
    },

    byId: stage => routeId => {
      if (!routeId) return {}
      if (typeof state.routes[routeId] === 'undefined') {
        setItem(state.routes, routeId, { reload: true, loading: true })
      }

      if (state.routes[routeId]?.reload) {
        state.routes[routeId].reload = false
        state.routes[routeId].loading = true

        actions.getRoute(useStore(), { routeId })
          .then(({ data: { route, stops } }) => { route.reload = false; route.loading = false; route.stops = stops; setItem(state.routes, routeId, route) })
          .catch(() => { setItem(state.routes, routeId, Infinity) })
      }

      return stage.routes[routeId]
    },

    getStates: stage => Object.keys(getStatesList(stage)),
    getSubStates: stage => state => {
      const states = getStatesList(stage)
      if (state && state in states) {
        return states[state]
      }
      return []
    },
    autoRefresh: state => state.autoRefresh,
    newTable: state => state.newTable,
  },
  mutations: {
    reloadProfile(state, routeId) {
      setItem(state.routeProfiles[routeId], 'reload', true)
    },
    overTimeView(state, val) {
      Store.setItem(state, 'overTimeView', val)
    },
    notificationView(state, val) {
      Store.setItem(state, 'notificationView', val)
    },
    setAutoRefresh(state, val) {
      state.autoRefresh = val
    },
    setNewTable(state, val) {
      state.newTable = val
    },
  },
  namespaced: true,
}
