export function initCallPlayer(player) {
  if (!(player instanceof Element)) {
    console.error('A suitable HTMLElement for the player is required')
    return
  }

  const timelineEl = player.querySelector('.pf-player__timeline')
  const trackVolume = player.querySelector('.pf-player__line')
  const progressVolume = player.querySelector('.pf-player__line-progress')
  const volumeBtn = player.querySelector('.pf-player__btn-volume')

  function generateError(selectors) {
    const str = selectors.map(it => `"${it}"`).join(' or ')
    return `There are not enough elements with selectors ${str} in the player`
  }

  if (!trackVolume || !progressVolume) {
    console.error(generateError(['.pf-player__line', '.pf-player__line-progress']))
    return
  }

  if (!volumeBtn) {
    console.error(
      generateError([
        '.pf-player__btn-play',
        '.pf-player__btn-volume',
        '.pf-player__btn',
        '.pf-player__speed-btn',
        '.pf-player__set-btn--speed',
      ]),
    )
    return
  }

  if (!player.dataset.audio) {
    console.error('The player does not have a data-audio attribute specified')
    return
  }

  const wavesurfer = window.WaveSurfer.create({
    container: timelineEl,
    waveColor: player.dataset.colorWave,
    progressColor: player.dataset.colorProgress || '#4AA3EF',
    // url: player.dataset.audio,
    height: 30,
    hideScrollbar: true,
    cursorWidth: 2,
    barWidth: 2,
  })
  wavesurfer.load(player.dataset.audio)

  function volumeHandler() {
    function trackHandler(evt) {
      const rect = trackVolume.getBoundingClientRect()
      const offsetX = evt.clientX - rect.left
      const trackWidth = rect.width
      let newLeft = offsetX

      if (newLeft < 0) {
        newLeft = 0
      } else if (newLeft > trackWidth) {
        newLeft = window.sliderWidth
      }

      const ratio = newLeft / trackWidth

      progressVolume.style.width = `${ratio * 100}%`

      wavesurfer.setVolume(ratio)
    }

    let isDragging = false

    trackVolume.addEventListener('click', trackHandler)

    trackVolume.addEventListener('mousedown', () => {
      isDragging = true
    })

    document.addEventListener('mouseup', () => {
      isDragging = false
    })

    trackVolume.addEventListener('mousemove', evt => {
      if (!isDragging) return
      trackHandler(evt)
    })
  }

  volumeHandler()

  return wavesurfer
}

/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
export function generateUUID() { // Public Domain/MIT
  let d = new Date().getTime()// Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0// Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else { // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

export function getMachineId() {
  let machineId = localStorage.getItem('MachineId')
  if (!machineId) {
    machineId = generateUUID()
    localStorage.setItem('MachineId', machineId)
  }
  return machineId
}

export function percent(num1, num2, type = 'diff') {
  num1 = parseInt(num1, 10)
  num2 = parseInt(num2, 10)

  if (num1 === 0 || num2 === 0) return '0'

  const abs = Math.abs(num1 - num2)
  let num = 0

  if (type === 'part') {
    num = 100 - ((abs / Math.max(num1, num2)) * 100)
    return `${num.toFixed()}%`
  }

  if (num1 > num2) {
    num = ((abs / Math.min(num1, num2)) * 100)
  } else {
    num = ((abs / Math.max(num1, num2)) * 100)
  }
  return `${Math.round(num)}%`
}

export function initChart(idNode, data, openCallback, types = null, isStacked = false) {
  window.Chart.defaults.elements.bar.borderRadius = 4
  if (!types) {
    types = this.chartTypes
  }

  const ticksFont = {
    color: '#94A3B8',
    font: {
      family: 'Inter',
      size: 12,
    },
    lineHeight: 16,
  }

  const chart = new window.Chart(document.getElementById(idNode), {
    type: 'bar',
    data: {
      labels: data.map(row => row.interval),
      datasets: types.map((it, i) => ({
        label: it.label,
        data: data.map(row => row.count[i]),
        backgroundColor: it.color,
      })),
    },
    options: {
      onClick: e => {
        const points = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
        if (!points.length) return

        const firstPoint = points[0]
        const timeInterval = chart.data.labels[firstPoint.index]
        // const count = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
        const { label } = chart.data.datasets[firstPoint.datasetIndex]

        openCallback(label, timeInterval)
      },
      scales: {
        x: {
          stacked: isStacked,
          grid: {
            display: false,
          },
          ticks: {
            ...ticksFont,
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          grid: {
            color: '#DBEAFE',
            // tickWidth: 1
          },
          ticks: {
            ...ticksFont,
            stepSize: 20,
          },
          border: {
            display: false,
          },
        },
      },
      barThickness: isStacked ? undefined : 14,
      maxBarThickness: isStacked ? undefined : 8,
      barPercentage: isStacked ? 0.5 : 1,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#ffffff', //
          titleFont: {
            family: 'Lato',
            size: 14,
            weight: 'bold',
          },
          bodyFont: {
            family: 'Inter',
            size: 12,
          },
          padding: 11,
          cornerRadius: 12,
          borderColor: '#4AA3EF40',
          borderWidth: 1,
          titleColor: '#111111',
          bodyColor: '#000000',
          displayColors: false,
          caretSize: 0,
          // external(context) {
          //   const tooltipEl = document.querySelector('.pf-chart-tooltip')

          //   if (!tooltipEl) {
          //     tooltipEl = document.createElement('div');
          //     document.body.appendChild(tooltipEl);
          // }

          //   const tooltipModel = context.tooltip
          //   if (tooltipModel.opacity === 0) {
          //     tooltipEl.style.opacity = 0
          //     return
          //   }
          //   if (tooltipModel.body)
          // }
        },
      },
    },
  })
  return chart
}

export function getDt(time) {
  return moment.unix(time).format('DD/MM/YYYY hh:mm A')
}

const earthRadius = 6371
const degreesToRadians = degrees => degrees * (Math.PI / 180)
const radiansToDegrees = radians => radians * (180 / Math.PI)
const greatCircleDistance = angle => 2 * Math.PI * earthRadius * (angle / 360) * 0.621371
const centralSubtendedAngle = (locationX, locationY) => {
  const locationXLatRadians = degreesToRadians(locationX.latitude)
  const locationYLatRadians = degreesToRadians(locationY.latitude)
  return radiansToDegrees(
    Math.acos(Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians)
      + Math.cos(locationXLatRadians) * Math.cos(locationYLatRadians)
      * Math.cos(degreesToRadians(Math.abs(locationX.longitude - locationY.longitude)))),
  )
}
export const getGeoDistance = (from, to) => greatCircleDistance(centralSubtendedAngle(from, to))

export function getObjectWithKeys(data, keys) {
  return Object.keys(data)
    .filter(key => keys.includes(key))
    .reduce((obj, key) => ({
      ...obj,
      [key]: data[key],
    }), {})
}

export function objectToFormData(obj, formData = new FormData(), parentKey = '') {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key
      if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
        objectToFormData(obj[key], formData, propName)
      } else {
        formData.append(propName, obj[key])
      }
    }
  }
  return formData
}

const iconsMap = {
  'Ready for Pickup': 'ready_pickup',
  'Ready to Print': 'ready_print',
  'Ready for Delivery': 'ready_delivery',
  'Route Optimized': 'optimized',
  'Delivery Attempted': 'attempted',
  'BACK TO PHARMACY': 'back_pharmacy',
  'READY TO OPTIMIZE': 'ready_optimize',
  'On its way to facility': 'to_facility',
}

export function iconSlug(name) {
  if (!name) return 'default'
  if (Object.keys(iconsMap).includes(name)) {
    name = iconsMap[name]
  }
  return String(name).toLocaleLowerCase().replaceAll(' ', '_')
}

export function icon(name) {
  if (!name) return ''
  return `i-${iconSlug(name)}`
}
