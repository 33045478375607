import axios from 'axios'

export const removeRecipient = ({ commit }, { recipientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullAddressBook.php', { recipientId }, { params: { action: 'removeRecipient' } })
    .then(data => resolve(data))
    .catch(error => reject(error))
})

export const saveRecipient = ({ commit }, { recipientId, name, email, phones, customize, addresses, lang }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullAddressBook.php', { recipientId, name, email, phones, customize, addresses, lang }, { params: { action: 'saveRecipient' } })
    .then(data => resolve(data))
    .catch(error => reject(error))
})

export const loadCsvImportProfilesRecipient = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCsvImportProfiles.php', {}, { params: { action: 'getCsvImportProfilesRecipient' } })
    .then(({ data }) => resolve(data.list))
    .catch(error => reject(error))
})

export const saveRecipientFromFile = ({ commit }, { filehash, template }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullAddressBook.php', { filehash, template }, { params: { action: 'saveRecipientFromFile' } })
    .then(data => resolve(data))
    .catch(error => reject(error))
})
