import * as Store from '@/utils/store.js'

const state = {
  ...Store.local({
  }),
}

export default {
  state,
  getters: {
  },
  mutations: {
  },
  namespaced: true,
}
