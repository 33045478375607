import * as actions from './actions'

const state = {
  load: false,
  page: 1,
  per_page: '25',
  total: null,
  reports: null,
  from: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  client: null,
}

export default {
  actions,
  state,
  getters: {
    load: state => state.load,
    from: state => state.from,
    to: state => state.to,
    client: state => state.client,
    total: state => state.total,
    page: state => state.page,
    perPage: state => state.per_page,
    paginatedReports: state => {
      const startIndex = (state.page - 1) * parseInt(state.per_page, 10)
      const endIndex = startIndex + parseInt(state.per_page, 10) - 1
      return state.reports?.filter((report, index) => index >= startIndex && index <= endIndex) || []
    },
  },
  mutations: {
    START_LOAD(state, data) {
      const newStateData = {
        load: true,
        page: data.page,
        per_page: data.per_page,
        from: data.from,
        to: data.to,
        client: data.client,
      }

      if (
        data.from !== state.from
        || data.to !== state.from
        || data.client !== state.client
      ) {
        newStateData.reports = null
        newStateData.page = 1
      }

      if (data.per_page !== state.per_page) {
        newStateData.page = 1
      }

      Object.assign(state, newStateData)
    },
    SET_REPORTS(state, data) {
      const startSlice = (data.page - 1) * state.per_page

      let transactionIndex = 0
      const reports = (state.reports ?? Array.from({ length: data.total }))
        .map((element, index) => {
          if (index >= startSlice && index <= data.transactions.length + startSlice - 1) {
            transactionIndex += 1
            return data.transactions[transactionIndex - 1]
          }
          return element
        })

      Object.assign(
        state,
        {
          page: data.page,
          total: data.total,
          reports,
          from: data.from,
          to: data.to,
          load: false,
          client: data.client,
        },
      )
    },
  },
  namespaced: true,
}
