import Rx4RouteRegistrations from './Rx4RouteRegistrations'
import Rx4RouteSubscriptions from './Rx4RouteSubscriptions'

export default {
  modules: {
    Rx4RouteRegistrations,
    Rx4RouteSubscriptions,
  },
  namespaced: true,
}
