import axios from 'axios'

export default {
  load: ({ commit }, page) => new Promise((resolve, reject) => {
    axios.get('/ajax/PullSubscriptions.php', {
      params: {
        action: 'getUsersToRegisterOnRx4Route',
        page,
      },
    }).then(({ data: { success, errors, data } }) => {
      if (success) {
        commit('SET_ITEMS', data)
      } else {
        reject(errors.join(', '))
      }
    })
  }),
}
