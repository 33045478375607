import axios from 'axios'
import { apiV1 } from '@/config'

export const setDataForTicket = async (context, ticket, additionalData) => {
  await context.commit('setClient', additionalData.client)
  await context.commit('setOrder', additionalData.order)
  await context.commit('setRecipient', additionalData.recipient)

  if (ticket.lat && ticket.lng) {
    await context.commit('setDriverLocation', { lat: ticket.lat, lng: ticket.lng })
  } else {
    await context.commit('setDriverLocation', additionalData.driver_location)
  }

  await context.commit('setCurrent', ticket)

  await context.dispatch('startTimer')
}

export const discardDataForTicket = async context => {
  await context.commit('setCurrent', null)

  await context.commit('setClient', null)
  await context.commit('setOrder', null)
  await context.commit('setRecipient', null)
  await context.commit('setDriverLocation', null)

  context.commit('setShowCancelModal', false)
  context.commit('setSubStatusModalShow', false)

  await context.dispatch('stopTimer')
}

export const stopWorkoutTimer = async context => {
  if (context.state.workoutTimer) {
    clearTimeout(context.state.workoutTimer)
    context.commit('setWorkoutTimer', null)
  }
}

export const stopWorkout = async context => {
  await context.dispatch('stopWorkoutTimer')

  await discardDataForTicket(context)

  context.commit('setWorkoutStarted', false)
  context.commit('setShowCancelModal', false)
  context.commit('setSubStatusModalShow', false)
}

export const getNext = async context => {
  await discardDataForTicket(context)

  if (!context.state.workoutStarted) {
    return () => { context.dispatch('stopWorkoutTimer') }
  }

  axios.get(`${apiV1}/dispatcher/stop-allow/today-count`)
    .then(response => {
      context.commit('setTodayCount', response.data.count)
    })

  const fetchNextTicket = async () => {
    if (!context.state.workoutStarted) {
      return () => { context.dispatch('stopWorkoutTimer') }
    }

    const { data } = await axios.post(`${apiV1}/dispatcher/stop-allow/get-next`, {
      orderType: context.state.requestOrderType,
    })

    if (data.success && data.ticket) {
      await context.dispatch('selectTicket', data.ticket)
      await context.dispatch('stopWorkoutTimer')
    } else if (!data.ticket) {
      const timerId = setTimeout(fetchNextTicket, 5000)
      context.commit('setWorkoutTimer', timerId)
    }
  }

  if (context.state.workoutStarted) {
    await fetchNextTicket()
  } else {
    await context.dispatch('stopWorkoutTimer')
  }

  return () => {
    context.dispatch('stopWorkoutTimer')
  }
}

export const selectTicket = async (context, ticket) => {
  const userId = context.rootGetters.currentUser.uid

  const { data } = await axios.post(`${apiV1}/dispatcher/stop-allow/start`, { ticketId: ticket.uid, userId })

  if (data.success) {
    const { data } = await axios.get(`${apiV1}/dispatcher/stop-allow/${ticket.uid}/additional-info`)
    if (data.error) {
      await discardDataForTicket(context)
      return
    }
    await setDataForTicket(context, ticket, data)
  }
}

export const declineTicket = async (context, payload = {}) => {
  if (!context.state?.current?.uid) {
    return
  }
  const {
    sub_status = null,
    activity_note = '',
    order_type = null,
    delivery_time = null,
    delivery_date = null,
    comment = null,
  } = payload

  const { data } = await axios.post(
    `${apiV1}/dispatcher/stop-allow/decline-ticket`,
    {
      id: context.state.current.uid,
      sub_status,
      activity_note,
      order_type,
      delivery_time,
      delivery_date,
      comment,
    },
  )

  context.commit('setDeclineModal', {
    show: false,
    presets: [],
  })

  context.commit('setDeclineDescription', '')

  if (data.success) {
    await getNext(context)
  }
}

export const approveTicket = async (context, payload = {}) => {
  const {
    sub_status = null,
    activity_note = '',
    order_type = null,
    delivery_time = null,
    delivery_date = null,
  } = payload

  const { data } = await axios.post(
    `${apiV1}/dispatcher/stop-allow/approve-ticket`,
    {
      id: context.state.current.uid,
      sub_status,
      activity_note,
      order_type,
      delivery_time,
      delivery_date,
    },
  )

  if (data.success) {
    await getNext(context)
  }
}

export const toModerate = async (context, ticketId) => {
  const { data } = await axios.post(
    `${apiV1}/dispatcher/stop-allow/to-moderate`,
    { ticketId },
  )

  if (data.success && ticketId === context.state.current.uid) {
    await discardDataForTicket(context)
  }
}

export const cantResolveTicket = async (context, ticketId) => {
  const { data } = await axios.post(
    `${apiV1}/dispatcher/stop-allow/add-solve-attempt`,
    { ticketId },
  )

  await discardDataForTicket(context)

  if (data.attempts > 0) {
    await getNext(context)
  }
}

export const checkProcessTicket = async (context, userId) => {
  const { data } = await axios.post(
    `${apiV1}/dispatcher/stop-allow/check-process-ticket`,
    { userId },
  )

  return data.ticket
}

export const touchTicket = async (context, ticketId) => {
  await axios.put(`${apiV1}/dispatcher/stop-allow/${ticketId}/touch`)
}
