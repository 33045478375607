import axios from 'axios'
import { apiV1 } from '@/config'

export const getRelocated = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', {}, { params: { action: 'getRelocated' } })
    .then(({ data }) => {
      commit('SET_RELOCATED', data.list)
      resolve(data.list)
    })
    .catch(error => {
      commit('SET_RELOCATED', [])
      reject(error)
    })
})
export const getUserDocs = ({ commit }, { userId }) => axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'getUserDocs' } }).then(({ data: { docs } }) => docs)

export const getFacilities = () => axios.get(`${apiV1}/common/get-facilities`).then(({ data: { facilities } }) => facilities)
export const setFacility = (ctx, { facility }) => axios.put(`${apiV1}/user/set-facility`, { facility })

export const givePermission = (ctx, { type, userId, facility }) => axios.post(`${apiV1}/user/permission/give`, { type, userId, facility })
export const takePermission = (ctx, { type, userId }) => axios.post(`${apiV1}/user/permission/take`, { type, userId })
export const setPreset = (ctx, { preset, userId, facility }) => axios.post(`${apiV1}/user/permission/set-preset`, { preset, userId, facility })
export const savePreset = (ctx, { preset }) => axios.post(`${apiV1}/user/permission/save-preset`, { preset })
export const removePreset = (ctx, { presetId }) => axios.post(`${apiV1}/user/permission/remove-preset`, { presetId })
export const getPermissionsUsers = ctx => axios.get(`${apiV1}/user/permission/get-list`)

export const getAllHubs = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFacilityHub.php', {}, { params: { action: 'getAllHubs' } })
    .then(({ data: { hubs } }) => { resolve(hubs) })
    .catch(error => { reject(error) })
})

export const getCurrentUser = ({ commit }, { clientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { clientId }, { params: { action: 'getCurrentUser' } })
    .then(({ data }) => { resolve(data.user) })
    .catch(error => { reject(error) })
})

export const getUsersByQuery = (ctx, { query, exclude, include, local }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { query, exclude, include, local }, { params: { action: 'getUsersByQuery' } })
    .then(({ data }) => {
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})

export const loginAs = ({ commit }, userId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'loginAs' } })
    .then(({ data }) => {
      commit('setAuthToken', data.token)
      resolve(data)
    })
    .catch(error => {
      reject(error)
    })
})

export const getPublicUserInfo = ({ commit }, { userId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'getPublicUserInfo' } })
    .then(({ data: { info } }) => resolve(info))
    .catch(error => reject(error))
})

export const getClientsList = (store, fields = []) => new Promise((resolve, reject) => {
  axios.get('/ajax/PullUsers.php', { params: { fields, action: 'getClientsList' } })
    .then(({ data: { clients } }) => resolve(clients))
    .catch(error => reject(error))
})
export const getDriversList = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullUsers.php', { params: { action: 'getDriversList' } })
    .then(({ data: { drivers } }) => resolve(drivers))
    .catch(error => reject(error))
})
export const getDispatchersList = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullUsers.php', { params: { action: 'getDispatchersList' } })
    .then(({ data: { dispatchers } }) => resolve(dispatchers))
    .catch(error => reject(error))
})

export const getUserProfile = ({ commit }, { userId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullUsers.php', { userId }, { params: { action: 'getUserProfile' } })
    .then(({ data: { user } }) => resolve(user))
    .catch(error => reject(error))
})

export const setUserInfo = ({ commit }, data) => new Promise((resolve, reject) => {
  // commit('SET_USER_INFO_SAVING_STATUS', true)

  axios.post('/ajax/PullUsers.php?action=setUserInfo', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    } })
    .catch(error => reject(error))
  // .finally(() => commit('SET_USER_INFO_SAVING_STATUS', false))
})
