import * as actions from './actions.js'

const state = {
  pade_statistics: {},
  filters: {
    date_from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
    clients: [],
  },
  options: {
    clients: [],
    hubs: [],
    delivery_methods: [
      { value: 'idrequired', label: 'FACE-TO-FACE ID & SIGNATURE REQUIRED', short_name: 'ID' },
      { value: 'face2face', label: 'FACE-TO-FACE SIGNATURE REQUIRED', short_name: 'F' },
      { value: 'inperson', label: 'FACE-TO-FACE NO SIGNATURE REQUIRED', short_name: 'NS' },
      { value: 'signlink', label: 'ONLINE SIGNATURE', short_name: 'on' },
      { value: 'nosign', label: 'SIGNATURE OPTIONAL', short_name: 'SOP' },
      { value: 'noask', label: 'NO-CONTACT DELIVERY', short_name: 'NO_CO' },
    ],
    types: {
      0: 'All types',
      1: 'Regular',
      2: 'Time Window Next Day',
      3: 'SAME DAY',
      4: 'Pharmacy Pickup',
      5: 'Time Window Next Day 9am-3pm, 3pm-9pm',
      6: 'Return to Pharmacy',
      7: 'STAT',
      8: 'Facility',
      9: 'Next Day Special Hours',
      10: 'Same Day Special Hours',
      11: 'Exchange',
    },
  },
  highlights: {
    hub: null,
    type: null,
    delivery_method: null,
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    padeStatistics: state => state.pade_statistics,
    filters: state => state.filters,
    options: state => state.options,
    highlights: state => state.highlights,
  },
  mutations: {
    SET_PADE_STATISTICS(state, payload) {
      state.pade_statistics = payload
    },
    SET_OPTIONS_CLIENTS(state, payload) {
      state.options.clients = payload
    },
    SET_OPTIONS_HUBS(state, payload) {
      state.options.hubs = payload
    },
    SET_FILTERS_DATE_FROM(state, payload) {
      state.filters.date_from = payload
    },
    SET_FILTERS_DATE_TO(state, payload) {
      state.filters.date_to = payload
    },
    SET_FILTERS_CLIENTS(state, payload) {
      state.filters.clients = payload
    },
    SET_HIGHLIGHTS(state, { hub, type, delivery_method }) {
      state.highlights = { hub, type, delivery_method }
    },
    RESET_HIGHLIGHTS(state) {
      state.highlights = { hub: null, type: null, delivery_method: null }
    },
  },
}
