import axios from 'axios'

export const getDocumentsToSign = (ctx, { clientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrdersDocuments.php', { clientId }, { params: { action: 'getDocumentsToSign' } })
    .then(({ data: { documents } }) => resolve(documents))
    .catch(e => {})
})

export const getApproveMethods = () => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', {}, { params: { action: 'getApproveMethods' } })
    .then(({ data: { methods } }) => resolve(methods))
    .catch(e => {})
})

export const getSubTypes = (ctx, { clientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', { clientId }, { params: { action: 'getSubTypes' } })
    .then(({ data: { subtypes } }) => resolve(subtypes))
    .catch(e => {})
})

export const getSuggestions = (ctx, { query }) => new Promise((resolve, reject) => {
  axios.post('/o/index/patients-typeahead', { }, { params: { query } })
    .then(({ data: { suggestions } }) => resolve(suggestions))
    .catch(e => {})
})

export const getPhoneSuggestions = (ctx, { query }) => new Promise((resolve, reject) => {
  axios.post('/o/index/recipient-phones-typeahead', { }, { params: { query } })
    .then(({ data: { suggestions } }) => resolve(suggestions))
    .catch(e => {})
})

export const checkDelivery = (ctx, { clientId, lat, lng }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullClients.php', { clientId, geo: { lat, lng } }, { params: { action: 'checkDelivery' } })
    .then(({ data: { types } }) => resolve(types))
    .catch(e => {})
})

export const getDefaultOrder = (ctx, { clientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', { client: clientId }, { params: { action: 'getDefaultOrder' } })
    .then(({ data: { order } }) => resolve(order))
    .catch(e => {})
})

export const getServicesList = (ctx, { clientId }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', { clientId }, { params: { action: 'getServicesList' } })
    .then(({ data: { services } }) => resolve(services))
    .catch(e => {})
})

export const createOrder = (ctx, { order }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrdersCreator.php', { order }, { params: { action: 'createOrder' }, toast: 'Saving, Please wait...' })
    .then(({ data: { created, label, paid } }) => resolve({ created, label, paid }))
    .catch(e => {})
})

export const saveOrder = (ctx, { order, reason }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrdersCreator.php', { order, reason }, { params: { action: 'saveOrder' }, toast: 'Saving, Please wait...' })
    .then(({ data: { created } }) => resolve(created))
    .catch(e => {})
})

export const createOrderCarrier = (ctx, { order }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrdersCreator.php', { order }, { params: { action: 'createOrderCarrier' }, toast: 'Saving, Please wait...' })
    .then(({ data: { status } }) => resolve({ status }))
    .catch(e => {})
})

export const mergeOrder = (ctx, { orderId, newData }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', { orderId, newData }, { params: { action: 'mergeOrder' }, toast: 'Merging, Please wait...' })
    .then(({ data }) => resolve(data))
    .catch(e => {})
})
