import axios from 'axios'

export const getQaqcFields = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullQaqc.php', { params: { action: 'getQaqcFields' } })
    .then(({ data: { fields } }) => resolve(fields))
    .catch(e => reject(e))
})

export const toggleWrongPicture = (ctx, { pictureId }) => axios
  .post('/ajax/PullNotes.php', { pictureId }, { params: { action: 'toggleWrongPicture' } })
  .then(({ data }) => data)

export const xxx = () => new Promise((resolve, reject) => {

})
