import * as actions from './actions.js'

const state = {
  favoriteOrders: {},
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    favoriteOrders: state => state.favoriteOrders,
  },
  mutations: {
    setFavoriteOrders(state, payload) {
      state.favoriteOrders = payload
    },
  },
}
