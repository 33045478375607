import axios from 'axios'

export const getFavorites = store => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFavorites.php', {}, { params: { action: 'getFavorites' } })
    .then(response => {
      store.commit('setFavoriteOrders', response.data.orders)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const favoriteOrder = (store, id) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFavorites.php', { orderIds: id }, { params: { action: 'favoriteOrder' } })
    .then(response => {
      store.dispatch('getFavorites').then(() => {
        resolve(response)
      })
    })
    .catch(error => {
      reject(error)
    })
})

export const getFavoriteOrders = (store, data) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullFavorites.php', { data }, { params: { action: 'getFavoriteOrders' } })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
