import axios from 'axios'

export const getNotifications = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullModalNotificationsTable.php', {}, { params: { action: 'getList' } })
    .then(({ data: { notifications } }) => {
      commit('SET_NOTIFICATIONS', notifications)
      resolve(notifications)
    })
    .catch(error => {
      commit('CLEAR_NOTIFICATIONS')
      reject(error)
    })
})

export const getReceivers = ({ commit }, notificationId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullModalNotificationsTable.php', { notificationId }, { params: { action: 'getReceiversList' } })
    .then(({ data: { notificationReceivers } }) => {
      commit('SET_RECEIVERS', notificationReceivers)
      resolve(notificationReceivers)
    })
    .catch(error => {
      commit('CLEAR_RECEIVERS')
      reject(error)
    })
})

export const createNotification = ({ commit }, notification) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullModalNotifications.php', { notification }, { params: { action: 'createNotification' } })
    .then(id => {
      resolve(id)
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const deleteNotification = ({ commit }, notificationId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullModalNotifications.php', { notificationId }, { params: { action: 'deleteNotification' } })
    .then(() => {
      resolve()
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const notificationRead = ({ commit }, notificationId) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullModalNotifications.php', { notificationId }, { params: { action: 'notificationRead' } })
    .then(({ data: { inserted } }) => {
      resolve(inserted)
    })
    .catch(error => {
      commit('CLEAR_DEPARTMENTS')
      reject(error)
    })
})

export const validateNotification = ({ commit }, notification) => {
  let isValid = true

  const validationData = {
    title: 'Notification Title is required',
    message_text: 'Message Text is required',
    show_seconds: 'Show Seconds is required',
    expired_in_min: 'Expired in is required',
  }

  for (const key in validationData) {
    if (!notification[key]) {
      commit('SET_ERROR', { key, error: validationData[key] })
      isValid = false
    } else {
      commit('SET_ERROR', { key, error: '' })
    }
  }

  let typeValid = false
  for (const type in notification.types) {
    if (Object.prototype.hasOwnProperty.call(notification.types, type)) {
      if (notification.types[type]) {
        typeValid = true
      }
    }
  }
  if (!typeValid) {
    isValid = false
    commit('SET_ERROR', { key: 'type', error: 'User Type is required' })
  } else {
    commit('SET_ERROR', { key: 'type', error: '' })
  }

  return isValid
}
