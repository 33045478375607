import Queue from './Queue/index.js'
import Center from './Center/index.js'
import Qaqc from './Qaqc/index.js'

export default {
  modules: {
    Queue,
    Center,
    Qaqc,
  },
  namespaced: true,
}
