import Accounting from './Accounting'
import Common from './Common'

const state = {
  view: 'table',
  filter: {
    month: null,
  },
  counts: {
    orderTypes: {},
  },
}

export default {
  namespaced: true,
  modules: {
    Accounting,
    Common,
  },
  state,
  getters: {
    view: state => state.view,
    filter: state => state.filter,
    counts: state => state.counts,
  },
  mutations: {
    setView(state, payload) {
      state.view = payload
    },
    setFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'month')) {
        state.filter.month = payload.month
      }
    },
    setCounts(state, payload) {
      state.counts = payload
    },
  },
}
