import actions from './actions'
import { setItem } from '@/helper.js'
import { useStore } from '@/store'

const state = {
  loading: true,
  config: null,
  periodVariants: null,
  subscriptionTypes: null,
  initialEditSubscriptionFormData: null,
}

export default {
  state,
  actions,
  getters: {
    isLoad: stage => {
      if (stage?.config === null) {
        setItem(stage, 'config', undefined)
        useStore().dispatch('Facilities/Rx4RouteSubscriptions/load')
      }
      if (stage?.config === undefined) {
        return true
      }
      return stage.loading
    },
    config: stage => stage.config,
    periodVariants: stage => stage.periodVariants,
    subscriptionTypes: stage => stage.subscriptionTypes,
    initialEditSubscriptionFormData: stage => stage.initialEditSubscriptionFormData,
  },
  mutations: {
    SET_LOAD(stage) {
      setItem(stage, 'loading', true)
    },
    SET_ITEMS(stage, {
      config,
      periodVariants,
      subscriptionTypes,
      initialEditSubscriptionFormData,
    }) {
      setItem(stage, 'loading', false)
      setItem(stage, 'config', config)
      setItem(stage, 'periodVariants', periodVariants)
      setItem(stage, 'subscriptionTypes', subscriptionTypes)
      setItem(stage, 'initialEditSubscriptionFormData', initialEditSubscriptionFormData)
    },
  },
  namespaced: true,
}
