import * as actions from './actions.js'
import { getShortHubName } from '@/pages/Scan/helpers/helpers'
// import { setItem, unsetItem } from '@/helper.js'

const state = {
  hasDriver: false,
  hubs: null,
  notificationError: {
    show: false,
    text: '',
  },
  textNotificationError: {
    show: false,
    orderId: null,
    text: null,
    seconds: 10,
  },
  autoSubmitOn: true,
  debounceTime: 350,
}

export default {
  namespaced: true,
  actions,
  state,
  getters: {
    autoSubmitOn: state => state.autoSubmitOn,
    hasDriver: state => state.hasDriver,
    notificationError: state => state.notificationError,
    textNotificationError: state => state.textNotificationError,
    hubs: state => state.hubs,
    debounceTime: state => state.debounceTime,
    hubsFormatted: state => Object.entries(state.hubs).reduce((formattedHubs, [hubId, hubData]) => {
      console.log('state: ', state)
      console.log('hubId: ', hubId)
      console.log('formattedHubs: ', formattedHubs)

      formattedHubs[hubId] = {
        id: hubId,
        name: hubData.title,
        color: hubData.color,
        shortName: getShortHubName(hubData.title),
      }
      return formattedHubs
    }, {}),
  },
  mutations: {
    SET_HAS_DRIVER(state, hasDriver) {
      state.hasDriver = hasDriver
    },
    SET_NOTIFICATION_ERROR(state, { show, text }) {
      state.notificationError.text = text
      state.notificationError.show = show
    },
    SET_TEXT_NOTIFICATION_ERROR(state, { show, orderId, text, seconds = 10 }) {
      state.textNotificationError.show = show
      state.textNotificationError.orderId = orderId
      state.textNotificationError.text = text
      state.textNotificationError.seconds = seconds
    },
    CLEAR_NOTIFICATION_ERROR(state) {
      state.notificationError.text = ''
      state.notificationError.show = false
    },
    CLEAR_TEXT_NOTIFICATION_ERROR(state) {
      state.textNotificationError.orderId = null
      state.textNotificationError.text = null
      state.textNotificationError.seconds = 10
      state.textNotificationError.show = false
    },
    SET_HUBS(state, hubs) {
      state.hubs = hubs
    },
    UPDATE_AUTO_SUBMIT_ON(state, payload) {
      state.autoSubmitOn = payload
    },
    UPDATE_DEBOUNCE_TIME(state, payload) {
      state.debounceTime = payload
    },
  },
}
