import * as actions from './actions'

const state = {
  queue: {},
  workoutStarted: false,
  current: null,
  todayCount: 0,
  client: null,
  order: null,
  recipient: null,
  driverLocation: null,
  timerSec: 0,
  extendMin: 5,
  timerIntervalId: null,
  declineModal: {
    show: false,
    title: 'Cancel the request',
    presets: [],
  },
  timerModal: {
    show: false,
  },
  declineDescription: '',
  subStatusModalShow: false,
  withoutScanningSteps: {
    1: true,
    2: false,
    3: false,
  },
  driverChatTickets: [],
  workoutTimer: null,
  showCancelModal: false,
  requestOrderType: null,
  leavePageModalShow: false,
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    queue: state => state.queue,
    workoutStarted: state => state.workoutStarted,
    current: state => state.current,
    client: state => state.client,
    order: state => state.order,
    recipient: state => state.recipient,
    todayCount: state => state.todayCount,
    timerSec: state => state.timerSec,
    extendMin: state => state.extendMin,
    driverLocation: state => state.driverLocation,
    subStatusModalShow: state => state.subStatusModalShow,
    workoutTimer: state => state.workoutTimer,
    showCancelModal: state => state.showCancelModal,
    requestOrderType: state => state.requestOrderType,
    leavePageModalShow: state => state.leavePageModalShow,
  },
  mutations: {
    setLeavePageModalShow(state, payload) {
      state.leavePageModalShow = payload
    },
    setRequestOrderType(state, payload) {
      state.requestOrderType = payload
    },
    setQueue(state, payload) {
      state.queue = payload
    },
    setWorkoutStarted(state, payload) {
      state.workoutStarted = payload
    },
    setTodayCount(state, payload) {
      state.todayCount = payload
    },
    setCurrent(state, payload) {
      state.current = payload
    },
    setClient(state, payload) {
      state.client = payload
    },
    setOrder(state, payload) {
      state.order = payload
    },
    setRecipient(state, payload) {
      state.recipient = payload
    },
    setDriverLocation(state, payload) {
      state.driverLocation = payload
    },
    setTimer(state, payload) {
      state.timerSec = payload
    },
    setTimerIntervalId(state, payload) {
      state.timerIntervalId = payload
    },
    setWorkoutTimer(state, payload) {
      state.workoutTimer = payload
    },
    setShowCancelModal(state, payload) {
      state.showCancelModal = payload
    },
    setDeclineModal(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'show')) {
        state.declineModal.show = payload.show
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'title')) {
        state.declineModal.title = payload.title
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'presets')) {
        state.declineModal.presets = payload.presets
      }
    },
    setDeclineDescription(state, payload) {
      state.declineDescription = payload
    },
    setWithoutScanningSteps(state, step) {
      for (const key in state.withoutScanningSteps) {
        if (Object.prototype.hasOwnProperty.call(state.withoutScanningSteps, key)) {
          state.withoutScanningSteps[key] = false
        }
      }
      state.withoutScanningSteps[step] = true
    },
    setTimerModal(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'show')) {
        state.timerModal.show = payload.show
      }
    },
    setSubStatusModalShow(state, payload) {
      state.subStatusModalShow = payload
    },
    addDriverChatTicketId(state, payload) {
      if (!state.driverChatTickets.includes(payload)) {
        state.driverChatTickets.push(payload)
      }
    },
  },
}
