import actions from './actions'
import { setItem } from '@/helper.js'
import { useStore } from '@/store'

const state = {
  loading: true,
  count: null,
  data: null,
  pages: null,
  page: null,
}

export default {
  state,
  actions,
  getters: {
    items: stage => {
      if (stage?.data === null) {
        setItem(stage, 'data', undefined)
        useStore().dispatch('Facilities/Rx4RouteRegistrations/load', 1)
      }
      if (stage?.data === undefined) {
        return []
      }

      return stage.data
    },
    count: state => state.count,
    loading: state => state.loading,
  },
  mutations: {
    SET_LOAD(stage) {
      setItem(stage, 'loadnig', true)
    },
    SET_ITEMS(stage, { count, page, pages, items }) {
      setItem(stage, 'data', items)
      setItem(stage, 'count', count)
      setItem(stage, 'page', page)
      setItem(stage, 'pages', pages)
      setItem(stage, 'loading', false)
    },
  },
  namespaced: true,
}
