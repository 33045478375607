import Integrations from './Integrations/index.js'
import * as Store from '@/utils/store.js'
import * as actions from './actions.js'
import { useStore } from '@/store'

const state = {
  ...Store.session({
  }),
  clientsList: undefined,
  driverList: undefined,
  dispatchersList: undefined,
}
export default {
  state,
  getters: {
    getClients: stage => (fields = []) => {
      if (stage.clientsList === undefined) {
        actions.getClientsList(useStore(), fields)
          .then(list => { Store.setItem(stage, 'clientsList', list) })
      }
      return stage.clientsList !== undefined ? Object.values(stage.clientsList) : []
    },
    getDrivers: stage => {
      if (stage.driverList === undefined) {
        actions.getDriversList(useStore())
          .then(list => { Store.setItem(stage, 'driverList', list) })
      }
      return stage.driverList !== undefined ? Object.values(stage.driverList) : []
    },
    getDispatchers: stage => {
      if (stage.dispatchersList === undefined) {
        actions.getDispatchersList(useStore())
          .then(list => { Store.setItem(stage, 'dispatchersList', list) })
      }
      return stage.dispatchersList !== undefined ? Object.values(stage.dispatchersList) : []
    },
  },
  modules: {
    Integrations,
  },
  namespaced: true,
}
