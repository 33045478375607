import axios from 'axios'

export const getRoutes = ({ commit }) => axios.post('/ajax/PullRoutes.php', {}, { params: { action: 'getRoutes' } }).then(({ data }) => data).catch(() => ({}))
export const getDrivers = ({ commit }) => axios.post('/ajax/PullDrivers.php', {}, { params: { action: 'getDriversNameAndUid' } }).then(({ data }) => data.drivers).catch(() => ({}))
export const getRoute = ({ commit }, { routeId }) => axios.post('/ajax/PullRoutes.php', { routeId }, { params: { action: 'getRoute' } })
export const getStates = ({ commit }) => axios.get('/ajax/PullRoutes.php', { params: { action: 'getStates' } })

export const getRouteProfile = ({ commit }, { routeId }) => axios.post('/ajax/PullRoutes.php', { routeId }, { params: { action: 'getRouteProfile' } }).then(({ data: { route } }) => route)

export const changeRouteName = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'changeRouteName' } }).catch(() => { commit('reloadProfile', post.routeId) })
export const setCurrentDate = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'setCurrentDate' } }).catch(() => { commit('reloadProfile', post.routeId) })
export const setCurrentSubState = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'setCurrentSubState' } }).catch(() => { commit('reloadProfile', post.routeId) })
export const setCurrentState = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'setCurrentState' } }).catch(() => { commit('reloadProfile', post.routeId) })
export const requestRouteImage = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'requestRouteImage' } }).catch(() => { commit('reloadProfile', post.routeId) })
export const toggleBypassGeofence = ({ commit }, post) => axios.post('/ajax/PullRoutes.php', post, { params: { action: 'toggleBypassGeofence' } })
  .catch(() => { commit('reloadProfile', post.routeId) })

export const generateRoutes = (ctx, { status, count, min, max }) => axios
  .post('/ajax/PullRoutes.php', { status, count, min, max }, { params: { action: 'generateRoutes' } })
  .then(({ data: { statusId } }) => statusId)
export const statusGenerateRoutes = (ctx, { statusId }) => axios
  .post('/ajax/PullRoutes.php', { statusId }, { params: { action: 'statusGenerateRoutes' } })
  .then(({ data }) => data)
export const resourceGenerateRoutes = (ctx, { resourceId }) => axios
  .post('/ajax/PullRoutes.php', { resourceId }, { params: { action: 'resourceGenerateRoutes' } })
  .then(({ data }) => data)
