import Reports from './Reports'
import CarrierRevenue from './CarrierRevenue'
import PadeStatistics from '@/store/Finances/PadeStatistics'
import NachaTransactions from './NachaTransactions'

export default {
  modules: {
    Reports,
    PadeStatistics,
    CarrierRevenue,
    NachaTransactions,
  },
  namespaced: true,
}
