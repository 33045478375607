import * as actions from './actions'
import { makeDate } from '@/utils/date'

const state = {
  list: {},
  // sort: {},
  filter: {
    facility: null,
  },
  // counts: {
  //   requestTypes: {},
  // },
  // summary: {
  //   created_started: {
  //     min: 0,
  //     max: 0,
  //     avg: 0,
  //   },
  //   started_finished: {
  //     min: 0,
  //     max: 0,
  //     avg: 0,
  //   },
  //   created_finished: {
  //     min: 0,
  //     max: 0,
  //     avg: 0,
  //   },
  // },
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    list: state => state.list,
    //   sort: state => state.sort,
    filter: state => state.filter,
  //   counts: state => state.counts,
  //   summary: state => state.summary,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    // setSort(state, payload) {
    //   if (Object.prototype.hasOwnProperty.call(payload, 'field')) {
    //     state.sort.field = payload.field
    //   }
    //   if (Object.prototype.hasOwnProperty.call(payload, 'direction')) {
    //     state.sort.direction = payload.direction
    //   }
    // },
    setFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(payload, 'facility')) {
        state.filter.facility = payload.facility
      }
    },
    // setCounts(state, payload) {
    //   if (Object.prototype.hasOwnProperty.call(payload, 'requestTypes')) {
    //     state.counts.requestTypes = payload.requestTypes
    //   }
    // },
    // setSummary(state, payload) {
    //   state.summary = payload
    // },
  },
}
