import * as actions from './actions.js'

import { setItem } from '@/helper.js'

const state = {
  calendarEvents: [],
}

const rnd = (a, b) => Math.floor((b - a + 1) * Math.random()) + a
const colors = [
  'red lighten-4', 'pink lighten-4', 'purple lighten-4',
  'red darken-4', 'pink darken-4', 'purple darken-4',
  'deep-purple', 'indigo', 'blue',
  'deep-purple accent-3', 'indigo accent-3', 'blue accent-3',
  'light-blue lighten-1', 'cyan lighten-1', 'teal lighten-1',
  'light-blue accent-4', 'cyan accent-4', 'teal accent-4',
  'green accent-4', 'light-green accent-4', 'lime accent-4',
  'yellow darken-4', 'amber darken-4', 'orange darken-4',
]

export default {
  actions,
  state,
  getters: {
    calendarEvents: state => state.calendarEvents,
  },
  mutations: {
    SET_CALENDAR_EVENTS(state, events) {
      const list = []

      events.forEach(origin => {
        list.push({
          start: new Date(origin.start_at_dt),
          end: new Date(origin.end_at_dt),
          name: origin.name,
          category: origin.category,
          color: colors[rnd(0, colors.length - 1)],
          timed: true,
          origin,
        })
      })
      setItem(state, 'calendarEvents', list)
    },
  },
  namespaced: true,
}
